export default {
  slugs: {
    singular: "mobilization-advance",
    plural: "mobilization-advances",
  },
  entityNames: {
    singular: "Mobilization Advance",
    plural: "Mobilization Advances",
  },
};
