export default {
    slugs: {
        singular: "sub-contractors-boq",
        plural: "sub-contractors-boq",
    },
    entityNames: {
        singular: "Sub Contractor Boq",
        plural: "Sub Contractors BOQ",
    },
};
