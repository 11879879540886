import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input, Table, Row, Col, Select } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import qs from "qs";
import _ from "lodash";
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/plots";
import * as XLSX from "xlsx";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { axios } from "../../../../../App";
import { MAX_PAGINATION_LIMIT } from "../../../../../constants";
import { DataTable } from "../../../components";
import config from "./config";
import { ReportDataTable } from "../../../../stocks/components";


const TrackerReport = () => {
  const { id } = useParams();


  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);
  const [chartData, setchartData] = useState([])
  const [subMilestoneData, setSubMilestoneData] = useState([]);
  const [phases, setPhases] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");


  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPhases = async (params = null) => {
    try {

      const queryObject = {
        populate: "*",

        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      console.log('phases response', response);


      if (response?.data) {
        const filter_by_phase = response?.data?.data?.filter((data) => data?.attributes?.phase?.data?.length > 0);

        setPhases(
          filter_by_phase?.map((item) => {
            const phases = item?.attributes?.phase?.data || [];

            const currentPhaseIndex = phases.length - 1;
            const previousPhaseIndex = phases.length - 2;

            const currentPhase = phases?.[0]?.attributes;
            const previousPhase = phases[previousPhaseIndex]?.attributes;

            const current_total_cost = currentPhase
              ? currentPhase.total_actual_budget + currentPhase.total_estimated_budget
              : null;

            const previous_total_cost = previousPhase
              ? previousPhase.total_actual_budget + previousPhase.total_estimated_budget
              : null;

            const actual_cost = currentPhase ? currentPhase?.total_actual_budget : 0;
            const estimated_cost = currentPhase ? currentPhase?.total_estimated_budget : 0

            return {
              id: item?.id,
              currentPhase: phases.length > 0 ? `R${currentPhaseIndex}` : '',
              prev_cost: previous_total_cost ?? 0,
              curr_cost: current_total_cost ?? 0,
              actual_cost,
              estimated_cost,
              ...item?.attributes,
            };
          })
        );

      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPhases();
    fetchProjects();
  }, []);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        // filters: {
        //   budget_tracker: id,
        // },
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };


      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `budget-tracker-phases?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const filter_by_tracker = response?.data?.data?.filter((data) => data?.attributes?.budget_tracker?.data !== null);


        setData(
          filter_by_tracker?.map((item, index) => ({
            id: item?.id,
            index: index,
            ...item?.attributes,
          }))
        );

        setchartData(
          response?.data?.data?.flatMap((item, i) => {
            // Extract milestone names, join them if there are multiple
            const milestoneNames = item?.attributes?.milestone?.data
              ?.map(m => m?.attributes?.name)
              .join(', ') || `ID ${item?.id}`;

            const phase = `R${i}`;

            return [
              {
                name: phase, // Use milestone names as the x-axis label
                type: 'Estimated Budget',
                value: item?.attributes?.total_estimated_budget,
              },
              {
                name: phase,
                type: 'Actual Budget',
                value: item?.attributes?.total_actual_budget,
              },
            ];
          })
        );


        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      // Extract the last object's sub_milestone data
      const lastObject = data?.[data.length - 1];
      const subMilestones = lastObject?.sub_milestone || {};


      const totalActualBudget = Object.values(subMilestones)
        .flatMap((milestoneGroup) =>
          milestoneGroup.map((milestone) => milestone.actual_budget)
        )
        .reduce((sum, budget) => sum + budget, 0);

      const totalEstimatedBudget = Object.values(subMilestones)
        .flatMap((milestoneGroup) =>
          milestoneGroup.map((milestone) => milestone.estimated_budget)
        )
        .reduce((sum, budget) => sum + budget, 0);

      // Flatten and transform the sub_milestone data into a chart-friendly format
      const chartData = Object.values(subMilestones).flatMap((milestoneGroup) =>
        milestoneGroup.map((milestone) => ({
          subMilestone: milestone.sub_milestone,
          actualBudget: milestone.actual_budget,
          estimatedBudget: milestone.estimated_budget,
        }))
      );

      setSubMilestoneData(chartData);
    }
  }, [data]);

  const refreshData = () => {
    onSearch();
  };


  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = []

    if (selectedProject !== "") {
      filters.push({
        project: selectedProject,
      });
    }

    fetchPhases({
      $and: filters
    })
  };

  const onReset = () => {
    setSelectedProject('');
    fetchPhases(null);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };


  const formattedData = subMilestoneData.flatMap((item) => [
    { subMilestone: item.subMilestone, type: 'Available Budget', value: item.actualBudget },
    { subMilestone: item.subMilestone, type: 'Allocated Budget', value: item.estimatedBudget },
  ]);

  const getColumnLetter = (colNum) => {
    let temp, letter = '';
    while (colNum > 0) {
      temp = (colNum - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      colNum = (colNum - temp - 1) / 26;
    }
    return letter;
  };


  // Utility function to calculate totals
  const calculateTotal = (item, field) => {
    let total = 0;
    Object.values(item.sub_milestone).forEach((subMilestones) => {
      subMilestones.forEach((subItem) => {
        total += parseFloat(subItem[field]) || 0;
      });
    });
    return total;
  };

  const exportToExcel = async (bifurcated_data) => {
    // Create a new workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Budget');

    // Define reusable styles
    const header_font = { size: 24, bold: true };
    const sub_header_font = { bold: true };
    const center_alignment = { horizontal: 'center', vertical: 'middle' };
    const highlight_fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFE0' }, // Light yellow background
    };
    const highlight_head_fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'CEEBF6' }, // Light blue-ish background
    };

    // How many columns each budget block should occupy
    // (A typical set: 8 columns for sub-milestone, labour, material, total, etc.)
    const columns_per_budget = 8;

    // If you want some empty columns between each budget’s block, adjust spacing
    const spacing = 1;

    // We'll keep track of the current row where we print each project block
    let current_row = 1;

    // -----------------------------------------------------------------
    // Loop over each project block in the bifurcated data
    // -----------------------------------------------------------------
    for (let p = 0; p < bifurcated_data.length; p++) {
      const project_block = bifurcated_data[p];
      const { project, budget_data } = project_block;

      // 1) Determine how many budgets are in this project
      //    so we can figure out total columns needed
      const budget_count = budget_data?.length || 0;
      if (budget_count === 0) {
        // If no budgets, skip (or handle differently if needed)
        continue;
      }

      // 2) Calculate total columns for this project's block
      //    e.g. each budget is columns_per_budget wide, plus spacing
      const total_project_columns =
        budget_count * (columns_per_budget + spacing);

      // 3) (Optional) Set each column width to a default
      //    e.g. 15 for readability
      for (let c = 1; c <= total_project_columns; c++) {
        worksheet.getColumn(c).width = 15;
      }

      // 4) Print a big merged header row for the Project Name
      //    Merged across all columns that this project will occupy
      {
        const start_col_letter = getColumnLetter(1); // always from column 1
        const end_col_letter = getColumnLetter(total_project_columns);
        worksheet.mergeCells(`${start_col_letter}${current_row}:${end_col_letter}${current_row}`);
        const project_header_cell = worksheet.getCell(`${start_col_letter}${current_row}`);
        project_header_cell.value = project?.name
          ? `Project: ${project.name}`
          : `Project #${p + 1}`;
        project_header_cell.font = header_font;
        project_header_cell.alignment = center_alignment;
        project_header_cell.fill = highlight_fill;
        current_row += 1;
      }

      // 5) Print each budget in a horizontally offset column block
      //    We'll keep track of how many rows we use so we can find the “tallest” block
      //    and then advance current_row accordingly after finishing all budgets
      let max_rows_used = 0;

      // Instead of printing them all in separate rows, we want them side by side.
      // So for each budget, we define a top row offset we can call budget_base_row.
      // We'll keep them all starting at the same row, so they align horizontally at the top.
      const budget_base_row = current_row;

      for (let b = 0; b < budget_count; b++) {
        const budget_item = budget_data[b];

        // 5a) figure out which columns this budget occupies
        const start_col = b * (columns_per_budget + spacing) + 1;
        const end_col = start_col + columns_per_budget - 1;

        // 5b) Print a merged header for "Budget #XX" in that column range
        {
          const start_col_letter = getColumnLetter(start_col);
          const end_col_letter = getColumnLetter(end_col);
          worksheet.mergeCells(`${start_col_letter}${budget_base_row}:${end_col_letter}${budget_base_row}`);
          const budget_header_cell = worksheet.getCell(`${start_col_letter}${budget_base_row}`);
          // budget_header_cell.value = `Budget #${budget_item.id}`;
          budget_header_cell.font = { bold: true, size: 14 };
          budget_header_cell.alignment = center_alignment;
          budget_header_cell.fill = highlight_fill;
        }

        // 5c) Print sub-headers (e.g., Sub-Milestone, Labour, Material, etc.) in the row below
        const headers_row = budget_base_row + 1;
        const sub_headers = [
          'Sub-Milestone',
          'Est. Labour',
          'Est. Material',
          'Est. Total',
          'Const. Area Cost',
          'Carpet Area Cost',
          'Const. Area',
          'Carpet Area',
        ];
        sub_headers.forEach((headerText, idx) => {
          const col_letter = getColumnLetter(start_col + idx);
          const cell = worksheet.getCell(`${col_letter}${headers_row}`);
          cell.value = headerText;
          cell.font = sub_header_font;
          cell.alignment = center_alignment;
          cell.fill = highlight_head_fill;
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });

        // 5d) Now we list all sub-milestones *vertically* under these sub-headers
        // We'll keep track of how many rows we end up using for the sub-milestones
        // so we can place a totals row after them
        let row_cursor = headers_row + 1;

        // sub_milestone is an object of milestoneId -> array of sub-items
        const sub_milestones_obj = budget_item?.sub_milestone || {};
        const milestone_data_array = budget_item?.milestone?.data || [];

        // For each milestoneId, we add a “Milestone name” row, then sub-milestones
        for (const milestone_id in sub_milestones_obj) {
          const sub_array = sub_milestones_obj[milestone_id];
          // find the milestone name if any
          let milestone_name = '';
          if (Array.isArray(milestone_data_array)) {
            const found = milestone_data_array.find((m) => m.id == milestone_id);
            milestone_name = found?.attributes?.name || '';
          }

          // 5d(i) "Milestone: ..." row
          {
            const start_col_letter = getColumnLetter(start_col);
            const end_col_letter = getColumnLetter(end_col);
            worksheet.mergeCells(`${start_col_letter}${row_cursor}:${end_col_letter}${row_cursor}`);
            const milestone_cell = worksheet.getCell(`${start_col_letter}${row_cursor}`);
            milestone_cell.value = milestone_name
              ? `Milestone: ${milestone_name}`
              : `Milestone #${milestone_id}`;
            milestone_cell.font = { bold: true, size: 12 };
            milestone_cell.alignment = center_alignment;
            milestone_cell.fill = highlight_fill;
            row_cursor += 1;
          }

          // 5d(ii) Each sub-item row
          sub_array.forEach((sub_item) => {
            const labour = Number(sub_item.estimated_labour_cost || 0);
            const material = Number(sub_item.estimated_material_cost || 0);
            const total_estimated = labour + material;

            const row_values = [
              sub_item.sub_milestone || '',
              labour,
              material,
              total_estimated,
              budget_item.construction_area_cost || 0,
              budget_item.carpet_area_cost || 0,
              budget_item.construction_area || 0,
              budget_item.carpet_area || 0,
            ];

            row_values.forEach((val, idx) => {
              const col_letter = getColumnLetter(start_col + idx);
              const cell = worksheet.getCell(`${col_letter}${row_cursor}`);
              cell.value = val;
              cell.alignment = { ...center_alignment, indent: idx === 0 ? 1 : 0 };
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            });
            row_cursor += 1;
          });
        }

        // 5e) Totals row for this budget
        {
          const total_labour_cost = calculate_total_cost(budget_item, 'estimated_labour_cost');
          const total_material_cost = calculate_total_cost(budget_item, 'estimated_material_cost');
          const totals_row_values = [
            'Budget Totals',
            total_labour_cost,
            total_material_cost,
            total_labour_cost + total_material_cost,
            budget_item.construction_area_cost || 0,
            budget_item.carpet_area_cost || 0,
            budget_item.construction_area || 0,
            budget_item.carpet_area || 0,
          ];

          totals_row_values.forEach((val, idx) => {
            const col_letter = getColumnLetter(start_col + idx);
            const cell = worksheet.getCell(`${col_letter}${row_cursor}`);
            cell.value = val;
            cell.font = { bold: true };
            cell.alignment = center_alignment;
            cell.fill = highlight_head_fill;
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
          row_cursor += 2; // a blank row after totals
        }

        // 5f) Check if we used more rows than any previous budget in this project
        const rows_used_here = row_cursor - budget_base_row;
        if (rows_used_here > max_rows_used) {
          max_rows_used = rows_used_here;
        }
      }

      // 6) After placing all budgets side by side for this project,
      //    move current_row down so the next project is well below
      current_row += max_rows_used + 2; // +2 for spacing
    }

    // 7) Generate Excel file buffer and save
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `Budget.xlsx`);
  };

  /**
   * Convert a 1-based column index to Excel column letters (1 -> A, 2 -> B, 28 -> AB, etc.)
   * If you already have a version of this, just keep your original.
   *
  
  /**
   * Summation of a given property (like "estimated_labour_cost")
   * across all sub_milestones in a budget item.
   */
  function calculate_total_cost(budget_item, cost_property) {
    let total = 0;
    if (!budget_item?.sub_milestone) return total;

    Object.values(budget_item.sub_milestone).forEach((arr) => {
      arr.forEach((sub_item) => {
        total += Number(sub_item[cost_property] || 0);
      });
    });
    return total;
  }


  /**
   * Helper: Summation of a given property (like "estimated_labour_cost")
   * across all sub_milestones in a budget item.
   */
  // function calculate_total_cost(budget_item, cost_property) {
  //   let total = 0;
  //   if (!budget_item?.sub_milestone) return total;

  //   Object.values(budget_item.sub_milestone).forEach((sub_array) => {
  //     sub_array.forEach((sub_item) => {
  //       if (sub_item[cost_property]) {
  //         total += Number(sub_item[cost_property]) || 0;
  //       }
  //     });
  //   });
  //   return total;
  // }

  function bifurcateBudgetDataByPhase(phaseData, budgetData) {
    // Step 1: Convert phaseData into a lookup keyed by phase id.
    //         Each entry in the map will store:
    //         - The project's data
    //         - Any other relevant info about the phase itself (if needed)
    const phaseLookup = {};

    for (const phaseItem of phaseData) {
      // The top-level "id" is the unique phase identifier
      const phaseId = phaseItem.id;

      // The project details will be in phaseItem.project.data.attributes
      // We'll store them in the map for easy reference later
      let projectDetails = null;
      if (
        phaseItem.project &&
        phaseItem.project.data &&
        phaseItem.project.data.attributes
      ) {
        projectDetails = phaseItem.project.data.attributes;
      }

      // Create an initial entry in the lookup
      phaseLookup[phaseId] = {
        phase_id: phaseId,
        project: projectDetails,  // or copy only certain fields if you want
        budget_data: []
      };
    }

    // Step 2: Go through each budget item and see which phase it belongs to.
    //         That is determined by budgetItem.budget_tracker.data.id
    for (const budgetItem of budgetData) {
      if (
        budgetItem.budget_tracker &&
        budgetItem.budget_tracker.data &&
        typeof budgetItem.budget_tracker.data.id !== 'undefined'
      ) {
        const trackerId = budgetItem.budget_tracker.data.id;

        // If our phaseLookup has that trackerId, push this budget item into it
        if (phaseLookup.hasOwnProperty(trackerId)) {
          phaseLookup[trackerId].budget_data.push(budgetItem);
        }
      }
    }

    // Step 3: The result we want is the array of objects from the lookup
    //         with each phase_id, project, and the array of budget items
    return Object.values(phaseLookup);
  }

  // Now call our function
  const result = bifurcateBudgetDataByPhase(phases, data);

  useEffect(() => {
    fetchData(filters);
  }, []);

  console.log('Fetching records', data, result)

  return (
    <>
      <ReportDataTable
        data={phases}
        config={{
          ...config,
          breadcrumbs: [...config?.breadcrumbs, {
            path: "",
            title: 'Excel Report',
          }],
          pageTitle: 'Excel Report',
          columns: config?.columns
            ?.map((column) => ({
              ...column,
              ...getColumnProps(column),
            }))
          // ?.concat({
          //   title: "Actions",
          //   fixed: "right",
          //   width: 100,
          //   selectable: false,
          //   render: ({ id: ro_id }) => {
          //     return (
          //       <Space>
          //         {/* <Tooltip
          //           placement="bottom"
          //           title={`Edit ${config?.entityNames?.singular}`}
          //         >
          //           <Button
          //             icon={<EditOutlined />}
          //             onClick={() =>
          //               navigate(
          //                 `/budget-tracker/list/edit/${id}?project=${project}&tracker=${ro_id}&name=${projectName}`
          //               )
          //             }
          //           />
          //         </Tooltip>

          //         <Popconfirm
          //           title={`Are you sure to remove this ${config?.entityNames?.singular}?`}
          //           okText="Yes"
          //           cancelText="No"
          //           onConfirm={() => deleteItem(ro_id)}
          //         >
          //           <Tooltip
          //             placement="bottom"
          //             title={`Remove ${config?.entityNames?.singular}`}
          //           >
          //             <Button type="danger" icon={<DeleteOutlined />} />
          //           </Tooltip>
          //         </Popconfirm> */}
          //       </Space>
          //     );
          //   },
          // }),
        }}
        loading={loading}
        pagination={pagination}

        custom_excel_report={() => exportToExcel(result)}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              <Col span={24}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col>

              <Col span={7}>
                <Select
                  value={selectedProject}
                  onChange={setSelectedProject}
                  options={projects}
                  style={{ width: "100%", marginTop: '20px' }}
                  placeholder="Select Project"
                />
              </Col>

            </Row>
            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
                {/* <Button onClick={() => exportToExcel(data)}>Generate Excel Report</Button> */}
              </Space>
            </div>
          </div>
        }
        buttons={
          <div>
            {/* <Tooltip placement="top" title="Add new item">
              <Button
                icon={<PlusOutlined />}
                onClick={() => navigate(`/budget-tracker/list/add/${id}?project=${project}&tracker=${data[data?.length - 1]?.id}&name=${projectName}`)}
              />
            </Tooltip> */}
          </div>
        }
      />

    </>
  );
};

export default TrackerReport;
