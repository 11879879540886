import React, { useState, useEffect } from "react";
import { DataTable, ReportDataTable } from "../../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input, Select, Row, Col } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined } from "@ant-design/icons";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Toast, ToastProvider } from "@chakra-ui/react";

const CummulativeReport = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [searchValues, setSearchValues] = useState({});
    const [materialGroupId, setMaterialGroupId] = useState("");
    const [values, setValues] = useState({
        procurement: [
            {
                value: 'agency-quotations',
                label: 'Agency',
            },
            {
                value: 'sub-contractor-quotations',
                label: 'Sub Contractor',
            },
            {
                value: 'vendor-quotations',
                label: 'Vendor',
            },
        ]
    });

    const [procurement, setProcurement] = useState('')
    const location = useLocation();

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");

    const fetchProjects = async () => {
        try {
            const response = await axios.get(`projects`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Projects",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setProjects(options);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = async (params = null) => {
        try {
            setLoading(true);

            const queryObject = {
                populate: "*",
                sort: ["estimated_amount:desc"],
            };


            if (!_.isEmpty(params)) {
                queryObject["filters"] = params;
            }

            const response = await axios.get(
                `${procurement}?${qs.stringify(queryObject)}`
            );

            if (response?.data) {
                // Using Promise.all to handle async operations within map
                const fetchInvoices = async () => {
                    const mappedData = await Promise.all(response.data.data.map(async (item) => {
                        const obj = {
                            populate: '*',
                            filters: {
                                status: "Released",
                                project: item?.attributes?.project?.data?.id,
                                vendor: item?.attributes?.vendor?.data?.id,
                                sub_contractor: item?.attributes?.sub_contractor?.data?.id,
                                agency: item?.attributes?.agency?.data?.id
                            }
                        };

                        try {
                            // Awaiting the axios call
                            const invoice_res = await axios.get(
                                `received-invoices?${qs.stringify(obj)}`
                            );

                            const company_res = await axios.get(`/projects/${item?.attributes?.project?.data?.id}?populate=*`);

                            return {
                                id: item?.id,
                                procurement: item.attributes?.vendor?.data ?? item.attributes?.sub_contractor?.data,
                                received_invoice: invoice_res?.data?.data?.[0],
                                company: company_res?.data?.data?.attributes?.company,
                                ...item?.attributes,
                            };
                        } catch (error) {
                            console.error("Error fetching invoices:", error);
                            return {
                                id: item?.id,
                                procurement: item.attributes?.vendor?.data ?? item.attributes?.sub_contractor?.data,
                                ...item?.attributes,
                                error: "Failed to fetch invoice",
                            };
                        }
                    }));

                    // Set the resolved data to state
                    setData(mappedData);

                    setTotal(response?.data?.meta?.pagination?.total);

                    setPagination({
                        ...response?.data?.meta?.pagination,
                        pageSize: 10,
                    });
                };

                // Invoke the async function
                fetchInvoices();

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const refreshData = () => {
        onSearch();
    };



    const getColumnFilterProps = ({ dataIndex, references = {} }) => {
        if (typeof references?.fieldName === "function") {
            const transformer = references?.fieldName;

            return {
                render: (data) => transformer(data?.data?.attributes) || "N/A",
            };
        } else {
            return {
                render: (data) =>
                    data?.data?.attributes?.[references?.fieldName] || "N/A",
            };
        }
    };

    const getColumnProps = (column) => {
        if (column?.references) {
            return getColumnFilterProps(column);
        }

        return column;
    };

    const onSearch = () => {
        let orConditions = {
            $or: [
                {
                    heading: {
                        $containsi: searchValues?.keyword?.toLowerCase(),
                    },
                },
            ]
        }

        let searchParams = orConditions;

        if (materialGroupId !== "") {
            searchParams = {
                $and: [
                    {
                        task: parseInt(materialGroupId),
                    },
                    orConditions,
                ],
            };
        }

        if (selectedProject !== "") {
            searchParams = {
                $and: [
                    {
                        project: selectedProject,
                    },
                    orConditions,
                ],
            };
        }

        fetchData(searchParams);
    };


    const exportToExcel = async (data) => {

        // Log the incoming data for debugging
        console.log('fndsjkfnsdklfn', data);

        // Create a new workbook instance
        const workbook_instance = new ExcelJS.Workbook();

        // Add a worksheet named "Annexure" to our workbook
        const annexure_worksheet = workbook_instance.addWorksheet('Annexure');

        /********************************************************************
         *  1) CALCULATE KEY VALUES FROM YOUR DATA
         ********************************************************************/
        // We will assign "data_item" to "data" for clarity.
        const data_item = data;

        /*
          1.1) total_work_order_value:
               This sums all material_item_notes => note.amount * note.quantity
        */
        const total_work_order_value = data_item.material_item_notes
            ? data_item.material_item_notes.reduce((acc, note) => {
                return acc + (note.amount * note.quantity);
            }, 0)
            : data_item.work_item_notes.reduce((acc, note) => {
                return acc + (note.amount * note.quantity);
            }, 0);

        /*
          1.2) previous_bill_amount:
               We sum all the reconcilation lines from item.received_invoice.attributes.reconciliation
               => rec.amount * (rec.received_quantity || 0) * (rec.amount_percentage / 100)
        */
        let previous_bill_amount = 0;
        if (
            data_item.received_invoice &&
            data_item.received_invoice.attributes &&
            data_item.received_invoice.attributes.reconciliation
        ) {
            previous_bill_amount = data_item.received_invoice.attributes.reconciliation.reduce(
                (acc, rec) => {
                    return acc + (rec.amount * (rec.received_quantity || 0) * rec.amount_percentage / 100);
                },
                0
            );
        }

        /*
          1.3) this_bill_amount:
               We look at the "released_percentage" from the first reconciliation,
               then compute how much "remaining" percentage is in the payment_schedules
               and apply that to total_work_order_value
        */
        let released_percentage = 0;
        if (
            data_item.received_invoice &&
            data_item.received_invoice.attributes &&
            data_item.received_invoice.attributes.reconciliation &&
            data_item.received_invoice.attributes.reconciliation.length > 0
        ) {
            released_percentage = parseFloat(
                data_item.received_invoice.attributes.reconciliation[0].amount_percentage || 0
            );
        }

        let sum_remaining_percentage = 0;
        if (data_item.payment_schedules && Array.isArray(data_item.payment_schedules)) {
            sum_remaining_percentage = data_item.payment_schedules.reduce((acc, ps) => {
                const pct = parseFloat(ps.amount_percentage || '0');
                return acc + pct;
            }, 0);

            // Subtract what is already released
            sum_remaining_percentage = sum_remaining_percentage - released_percentage;
        }

        // The portion for "this_bill_amount"
        const this_bill_amount =
            (sum_remaining_percentage / 100) * total_work_order_value;

        /*
          1.4) cumulative_bill_amount:
               Just previous_bill_amount + this_bill_amount
        */
        const cumulative_bill_amount = previous_bill_amount + this_bill_amount;

        /********************************************************************
         *  2) SET UP THE SHEET’S LAYOUT SIMILAR TO YOUR SCREENSHOT
         ********************************************************************/

        // Adjust approximate column widths
        annexure_worksheet.columns = [
            { key: 'col1', width: 20 },
            { key: 'col2', width: 20 },
            { key: 'col3', width: 20 },
            { key: 'col4', width: 20 },
            { key: 'col5', width: 20 },
            { key: 'col6', width: 20 },
            { key: 'col7', width: 20 },
            { key: 'col8', width: 20 },
            { key: 'col9', width: 20 },
        ];

        /*
          Row 1: A single-title row, merged across columns (A1:I1).
          We'll add styling: larger font, centered, bold, color fill, etc.
        */
        const title_row_obj = annexure_worksheet.addRow(['Payble Amount For This Bill']);
        annexure_worksheet.mergeCells(`A1:I1`);
        title_row_obj.font = { bold: true, size: 14, color: { argb: 'FFFFFFFF' } }; // white text
        title_row_obj.alignment = { horizontal: 'center' };
        // A subtle bluish fill
        title_row_obj.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4472C4' } // a deep blue color
        };

        /*
          Row 2: Project name, Work Order No, Bill No
        */
        const row_2 = annexure_worksheet.addRow([]);
        row_2.getCell(1).value = 'Project name:';
        row_2.getCell(2).value = data_item.project?.data?.attributes?.name || 'N/A';
        row_2.getCell(4).value = 'Work Order No:';
        // We'll get the order number from the invoice no or the item.uid
        row_2.getCell(5).value =
            data_item.received_invoice?.attributes?.no || data_item.uid || 'N/A';

        /*
          Row 3: Company name, date, etc.
        */
        const row_3 = annexure_worksheet.addRow([]);
        row_3.getCell(1).value = 'Company name:';
        row_3.getCell(2).value = data_item?.company?.data?.attributes?.name;

        /*
          Row 4: Main Task, Vendor name
        */
        const row_4 = annexure_worksheet.addRow([]);
        row_4.getCell(1).value = 'Main Task:';
        row_4.getCell(2).value = data_item.task?.data?.attributes?.name || 'N/A';
        row_4.getCell(4).value = 'Vendor Name:';
        row_4.getCell(5).value = data_item.vendor?.data?.attributes?.name || 'N/A';

        /*
          Row 5: Contract Type, total_work_order_value, total % of work done
        */
        const row_5 = annexure_worksheet.addRow([]);
        row_5.getCell(1).value = 'Contract TYPE:';
        row_5.getCell(2).value = 'part M+L';
        row_5.getCell(4).value = 'Work order Value excluding tax';
        row_5.getCell(5).value = total_work_order_value;
        row_5.getCell(7).value = 'Total Percentage of work done';
        row_5.getCell(8).value = `${released_percentage.toFixed(2)}%`;

        // Blank row
        annexure_worksheet.addRow([]);

        /*
          Row 7: a heading that says "1 <task name>"
        */
        const row_7 = annexure_worksheet.addRow([
            '1',
            `${data_item.task?.data?.attributes?.name || ''}`,
        ]);
        // Merge from B7 to I7 for a single wide cell
        annexure_worksheet.mergeCells(`B7:I7`);

        /********************************************************************
         *  3) FILL OUT THE BILL AMOUNTS TABLE (like in your screenshot)
         ********************************************************************/

        /*
          Headings row (like "Previous Bill Amount", "This Bill Amount", "Cumulative Bill Amount")
          We'll style it with a bold white font and a darker fill.
        */
        const heading_row_obj = annexure_worksheet.addRow([
            '',
            '',
            'Previous Bill Amount',
            'This Bill Amount',
            'Cumulative Bill Amount',
        ]);
        annexure_worksheet.mergeCells(`A8:B8`);
        heading_row_obj.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        heading_row_obj.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '5B9BD5' } // a medium blue color
        };

        /*
          "Total" row
          We'll highlight it in a slightly different shade, make it bold.
        */
        const total_row_obj = annexure_worksheet.addRow([
            'Total',
            '',
            previous_bill_amount,
            this_bill_amount,
            cumulative_bill_amount,
        ]);
        annexure_worksheet.mergeCells(`A9:B9`);
        total_row_obj.font = { bold: true };
        total_row_obj.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D0CECE' } // a light gray
        };

        /*
          IGST row (just showing zero in your example).
          We'll keep it uncolored or lightly fill if you'd like. For now, let's keep it normal.
        */
        const igst_row_obj = annexure_worksheet.addRow(['IGST', '', 0, 0, 0]);

        // We'll gather CGST/SGST from your data
        const cgst_rate = data_item.material_item_notes
            ? data_item.material_item_notes.reduce((acc, note) => acc + (note.cqst || 0), 0)
            : data_item.work_item_notes.reduce((acc, note) => acc + (note.cqst || 0), 0);

        const sgst_rate = data_item.material_item_notes
            ? data_item.material_item_notes.reduce((acc, note) => acc + (note.sqst || 0), 0)
            : data_item.work_item_notes.reduce((acc, note) => acc + (note.cqst || 0), 0);

        const prev_cgst_rate = data_item?.received_invoice?.attributes?.reconciliation?.reduce(
            (acc, note) => acc + (note.cqst || 0), 0
        ) || 0;

        const prev_sgst_rate = data_item?.received_invoice?.attributes?.reconciliation?.reduce(
            (acc, note) => acc + (note.sqst || 0), 0
        ) || 0;

        const this_bill_cgst_amount = (cgst_rate / 100) * this_bill_amount;
        const this_bill_sgst_amount = (sgst_rate / 100) * this_bill_amount;

        const prev_cgst_amount = (prev_cgst_rate / 100) * previous_bill_amount;
        const prev_sgst_amount = (prev_sgst_rate / 100) * previous_bill_amount;

        // CGST row
        const cgst_row_obj = annexure_worksheet.addRow([
            'CGST',
            '',
            prev_cgst_amount,
            this_bill_cgst_amount,
            '' // or the cumulative if you want, left as blank to match your screenshot
        ]);

        // SGST row
        const sgst_row_obj = annexure_worksheet.addRow([
            'SGST',
            '',
            prev_sgst_amount,
            this_bill_sgst_amount,
            ''
        ]);

        // Add a blank row for spacing
        annexure_worksheet.addRow([]);

        /*
          Net Amount row => net_previous_bill, net_this_bill, net_cumulative
        */
        const net_this_bill = this_bill_amount + this_bill_cgst_amount + this_bill_sgst_amount;
        const net_previous_bill = previous_bill_amount + prev_cgst_amount + prev_sgst_amount;
        const net_cumulative = net_previous_bill + net_this_bill;

        const net_amount_row_obj = annexure_worksheet.addRow([
            'Net Amount',
            '',
            net_previous_bill,
            net_this_bill,
            net_cumulative,
        ]);
        net_amount_row_obj.font = { bold: true };
        // A light color fill to highlight it
        net_amount_row_obj.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FCE4D6' } // soft peach color
        };

        /*
          Less Retention row => 5% of base amounts.
        */
        const retention_percentage = 5;
        const this_bill_retention = this_bill_amount * 0.05;
        const previous_bill_retention = previous_bill_amount * 0.05;

        const retention_row_obj = annexure_worksheet.addRow([
            'Less Retention 5%',
            '',
            previous_bill_retention,
            this_bill_retention,
            previous_bill_retention + this_bill_retention,
        ]);
        retention_row_obj.font = { bold: true };
        retention_row_obj.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF2CC' } // pale yellow
        };

        /*
          Less Advance recovery => in your screenshot it was 0
        */
        const advance_row_obj = annexure_worksheet.addRow([
            'Less Advance recovery',
            '',
            0,
            0,
            0,
        ]);

        // Another blank row
        annexure_worksheet.addRow([]);

        /*
          Final Net Amount row => net minus retentions
        */
        const net_amount_row_2_obj = annexure_worksheet.addRow([
            'Net Amount',
            '',
            net_previous_bill - previous_bill_retention,
            net_this_bill - this_bill_retention,
            net_cumulative - (previous_bill_retention + this_bill_retention),
        ]);
        net_amount_row_2_obj.font = { bold: true };
        net_amount_row_2_obj.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'C6E0B4' } // soft green
        };

        /********************************************************************
         *  4) FINALIZE AND SAVE THE FILE
         ********************************************************************/

        // Now that the data and styling is in place, we write to a buffer:
        const buffer = await workbook_instance.xlsx.writeBuffer();

        // Convert buffer to a blob
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Use FileSaver to save the file
        saveAs(blob, 'Payable_Bill.xlsx');
    };

    const onReset = () => {
        setSearchValues({});
        fetchData({
            filters: {
                status: "Approved",
            },
        });
    };

    const getResultsMessage = () => {
        if (!_.isEmpty(searchValues)) {
            return total > 0 ? (
                <span>
                    <strong>{total}</strong> matched results
                </span>
            ) : (
                <span>No matched results</span>
            );
        }

        return (
            <span>
                <strong>{total}</strong> total records
            </span>
        );
    };

    useEffect(() => {
        const queryString = location?.search?.substring(1);

        if (queryString !== "") {
            const queryObject = qs.parse(queryString);

            if (queryObject?.taskId) {
                setMaterialGroupId(`${queryObject?.taskId}`);

                fetchData({
                    $and: [
                        {
                            task: parseInt(queryObject?.taskId),
                        },
                        {
                            status: "Approved",
                        },
                        {
                            project: selectedProject
                        }
                    ],
                });
            } else {
                fetchData({
                    status: "Approved",
                    project: selectedProject

                });
            }
        } else {
            fetchData({
                status: "Approved",
                project: selectedProject
            });

            fetchProjects()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.search]);


    console.log('Cummulative Report', data)

    return (
        <ToastProvider>

            <ReportDataTable
                data={data}
                config={{
                    ...config,
                    columns: config?.columns
                        ?.map((column) => ({
                            ...column,
                            ...getColumnProps(column),
                        }))
                        ?.concat({
                            title: "Actions",
                            width: 100,
                            selectable: false,
                            render: (item) => {
                                return (
                                    <Space style={styles.row}>
                                        <Tooltip
                                            placement="top"
                                            title={`Download Report`}
                                        >
                                            <Button
                                                icon={<DownloadOutlined />}
                                                onClick={() => exportToExcel(item)}

                                            />
                                        </Tooltip>
                                    </Space>
                                );
                            },
                        }),

                }}
                loading={loading}
                pagination={pagination}
                actions={{
                    onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
                    onRefresh: refreshData,
                }}
                Toolbar={
                    <div className="toolbar">
                        <Row gutter={16}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                <label htmlFor="">Select Procurement</label>
                                <Select
                                    value={procurement}
                                    onChange={setProcurement}
                                    options={values?.procurement}
                                    placeholder="Select a procurement"
                                    style={{ width: "300px", marginTop: '10px' }}
                                />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                                <label htmlFor="">Select Project</label>
                                <Select
                                    value={selectedProject}
                                    onChange={setSelectedProject}
                                    options={projects}
                                    placeholder="Select Project"
                                    style={{ width: "300px", marginTop: '10px' }}
                                />
                            </div>

                            <Input
                                value={searchValues?.keyword}
                                placeholder="Search for keyword..."
                                style={{ width: "100%", margin: '10px' }}
                                onChange={(e) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        keyword: e.target.value,
                                    }))
                                }
                            />

                        </Row>

                        <div className="toolbar-buttons">
                            <p className="toolbar-results">{getResultsMessage()}</p>

                            <Space>
                                <Button onClick={onReset}>Reset</Button>
                                <Button type="primary" onClick={onSearch}>
                                    Search
                                </Button>
                            </Space>
                        </div>
                    </div>
                }
                buttons={[
                    <Tooltip placement="top" title="Refresh">
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={refreshData}
                            loading={loading}
                        />
                    </Tooltip>,
                ]}
            />
        </ToastProvider>
    );
};

const styles = {
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
};

export default CummulativeReport;
