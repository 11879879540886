import React from "react";
import { Route } from "react-router-dom";
import {
    ReceivedInvoiceList,
    ReceivedInvoiceAdd,
    ReceivedInvoiceEdit,
} from "../pages/PaymentTracker";

export const register = (props) => {
    const basePath = "payment-tracker";

    return (
        <>
            <Route path={basePath} element={<ReceivedInvoiceList />} exact={true} />
            <Route path={`${basePath}/add`} element={<ReceivedInvoiceAdd />} />
            <Route path={`${basePath}/edit/:id`} element={<ReceivedInvoiceEdit />} />
        </>
    );
};
