import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";


import * as BudgetTrackers from "./budget-tracker.route";

import BudgetTracker from "..";
import BudgetReports from "../pages/Reports/Reports";
import TrackerReport from "../pages/Reports/List";
import VendorLedgerReport from "../pages/Reports/VendorLedger";
import BalanceToPay from "../pages/Reports/BalanceToPay";
import VendorMasterData from "../pages/Reports/MasterData/VendorMasterData";
import PaymentTracker from "../pages/Reports/PaymentTracker/PaymentTracker";
import MobilizationAdvance from "../pages/MobilizationAdvance/List";
import AddMobilization from "../pages/MobilizationAdvance/Add";
import MobilizationAdvanceReport from "../pages/Reports/MobilizationAdvance/List";
import CummulativeReport from "../pages/Reports/CummulativeReport/List";

export const register = (props) => {
  const basePath = "/budget-tracker";

  return (
    <Route path={basePath} element={<BudgetTracker />}>

      {BudgetTrackers.register()}

      <Route path={`${basePath}/mobilization-advance`} element={<MobilizationAdvance />} />
      <Route path={`${basePath}/mobilization-advance/add`} element={<AddMobilization />} />
      <Route path={`${basePath}/report`} element={<BudgetReports />} />
      <Route path={`${basePath}/report/activity-budget`} element={<TrackerReport />} />
      <Route path={`${basePath}/report/vendors-ledger`} element={<VendorLedgerReport />} />
      <Route path={`${basePath}/report/vendors-masterdata`} element={<VendorMasterData />} />
      <Route path={`${basePath}/report/payment-tracker`} element={<PaymentTracker />} />
      <Route path={`${basePath}/report/balance-to-pay`} element={<BalanceToPay />} />
      <Route path={`${basePath}/report/mobilization-advance`} element={<MobilizationAdvanceReport />} />
      <Route path={`${basePath}/report/cummulative`} element={<CummulativeReport />} />


      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
