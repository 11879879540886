import config from "../config";

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: config?.entityNames?.plural,
  breadcrumbs: [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "",
      title: config?.entityNames?.plural,
    },
  ],
  columns: [
    // {
    //   title: "Project Name",
    //   dataIndex: "project",
    //   selectable: true,
    //   references: {
    //     fieldName: "name",
    //   },
    // },
    {
      title: "Purchase Order",
      dataIndex: "po",
      selectable: true,
      render: (text) => <p>{text?.attributes?.heading}</p>,
    },
    {
      title: "Recieved Invoice",
      dataIndex: "received_invoice",
      selectable: true,
      references: {
        fieldName: "no",
      },
    },
    {
      title: "Current Bill Amount",
      dataIndex: "current_bill_recovery",
      selectable: true,
    },

    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   selectable: true,
    //   sorter: (a, b) => a?.description?.localeCompare(b?.description),
    //   render: (text) =>
    //     text !== "" ? (
    //       <span
    //         className="html-cell"
    //         dangerouslySetInnerHTML={{ __html: text }}
    //       ></span>
    //     ) : (
    //       "N/A"
    //     ),
    // },
  ],
  ...config,
};
