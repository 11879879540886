import config from "../List/config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `vendors-list`,
  pageTitle: `Payment Tracker Report`,
  code: "VENDOR_QUOTATIONS",
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/budget-tracker/report`,
      title: "Reports",
    },
    {
      path: "",
      title: `Payment Tracker Report`,
    },
  ],
  columns: [
    {
      title: "Type",
      dataIndex: "invoice_type",
      selectable: true,
    },
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
    },
    {
      title: "Amount Payable (₹)",
      dataIndex: "amount_payable",
      selectable: true,
      sorter: (a, b) => a?.amount_payable - b?.amount_payable,
    },
  ],

};
