import React from "react";
import { Route, Navigate } from "react-router-dom";
import NotFound from "../../../pages/NotFound";
import QuickStocks from "../index";

import * as GstTypes from "./gst-types.route";
import * as TdsTypes from "./tds-types.route";
import * as WorkOrderGroups from "./work-order-groups.route";
import * as AgencyGroups from "./agency-groups.route";
import * as VendorGroups from "./vendor-groups.route";
import * as SubContractorGroups from "./sub-contractor-groups.route";
import * as Manufacturers from "./manufacturers.route";
import * as ToolTypes from "./tool-types.route";
import * as Machines from "./machines.route";
import * as HandTools from "./hand-tools.route";
import * as DebitNotes from "./debit-notes.route";
// import * as CreditNotes from "./credit-notes.route";
// import * as ExtraWorks from "./extra-works.route";
import * as CreatedInvoices from "./created-invoices.route";
import * as ReceivedInvoices from "./received-invoices.route";
import * as PaymentTracker from "./payment-tracker.route";
import * as ReleasedNotes from "./released-notes.route";
import * as MaterialGroups from "./material-groups.route";
import * as MaterialUnits from "./material-units.route";
import * as MaterialBrands from "./material-brands.route";
import * as MachineLogs from "./machine-logs.route";
import * as HandToolLogs from "./hand-tool-logs.route";
import * as MaterialItems from "./material-items.route";
// import * as ReceivedNotes from "./vendor-received-notes.route";
import * as StockLedgers from "./stock-ledgers.route";
import * as Dashboard from "./dashboard.route";
import * as Consumables from "./consumables.route";
import * as VendorPaymentSchedules from "./vendor-payment-schedules.route";
import * as VendorTerms from "./vendor-terms.route";
import * as VendorMaterialRequisitions from "./vendor-material-requisitions.route";
import * as VendorQuotations from "./vendor-quotations.route";
import * as AgencyTerms from "./agency-terms.route";
import * as SubContractorTerms from "./sub-contractor-terms.route";
import * as AgencyPaymentSchedules from "./agency-payment-schedules.route";
import * as SubContractorPaymentSchedules from "./sub-contractor-payment-schedules.route";
import * as VendorPurchaseOrders from "./vendor-purchase-orders.route";
import * as AgencyRequirementForms from "./agency-requirement-forms.route";
import * as SubContractorRequirementForms from "./sub-contractor-requirement-forms.route";
import * as SubContractorWorkOrders from "./sub-contractor-work-orders.route";
import * as AgencyWorkOrders from "./agency-work-orders.route";
import * as SubContractorQuotations from "./sub-contractor-quotations.route";
import * as AgencyQuotations from "./agency-quotations.route";
import * as SubContractors from "./sub-contractors.route";
import * as Agencies from "./agencies.route";
import * as Vendors from "./vendors.route";
import * as Reports from "./reports.route";
import * as VendorQuotationComparisonLists from "./vendor-quotation-comparison-lists.route";
import * as AgencyQuotationComparisonLists from "./agency-quotation-comparison-lists.route";
import * as SubContractorQuotationComparisonLists from "./sub-contractor-quotation-comparison-lists.route";
import * as RequiredTaskMaterials from "./required-task-materials.route";
import * as SubContractorDebitNotes from "./sub-contractor-debit-notes.route";
import * as SubContractorCreditNotes from "./sub-contractor-credit-notes.route";
import * as SubContractorExtraWorks from "./sub-contractor-extra-works.route";
import * as VendorDebitNotes from "./vendor-debit-notes.route";
import * as VendorExtraWorks from "./vendor-extra-works.route";
import * as VendorCreditNotes from "./vendor-credit-notes.route";
import * as AgencyDebitNotes from "./agency-debit-notes.route";
import * as AgencyExtraWorks from "./agency-extra-works.route";
import * as AgencyCreditNotes from "./agency-credit-notes.route";
import * as InventorySettings from "./inventory-settings.route";
import * as WorkGroups from "./work-groups.route";
import * as WorkItems from "./work-items.route";
import * as WorkUnits from "./work-units.route";
import * as VendorMaterialReceivables from "./vendor-material-receivables.route";
import * as quotations from "./quotations.route";

import CashFlowTracker from "../pages/CashFlowTracker/Report";
import CummilativeReport from "../pages/CummulativeReport/List";

export const register = (props) => {
  const basePath = "/quick-stocks";

  return (
    <Route path={basePath} element={<QuickStocks />}>
      {GstTypes.register()}
      {TdsTypes.register()}
      {WorkOrderGroups.register()}
      {AgencyGroups.register()}
      {VendorGroups.register()}
      {SubContractorGroups.register()}
      {Manufacturers.register()}
      {ToolTypes.register()}
      {Machines.register()}
      {HandTools.register()}
      {DebitNotes.register()}
      {/* {CreditNotes.register()}
      {ExtraWorks.register()} */}
      {CreatedInvoices.register()}
      {ReceivedInvoices.register()}
      {ReleasedNotes.register()}
      {MaterialGroups.register()}
      {MaterialUnits.register()}
      {MaterialBrands.register()}
      {MachineLogs.register()}
      {HandToolLogs.register()}
      {MaterialItems.register()}
      {/* {ReceivedNotes.register()} */}
      {StockLedgers.register()}
      {Dashboard.register()}
      {Consumables.register()}
      {VendorPaymentSchedules.register()}
      {VendorTerms.register()}
      {VendorMaterialRequisitions.register()}
      {VendorQuotations.register()}
      {AgencyTerms.register()}
      {SubContractorTerms.register()}
      {AgencyPaymentSchedules.register()}
      {SubContractorPaymentSchedules.register()}
      {VendorPurchaseOrders.register()}
      {AgencyRequirementForms.register()}
      {SubContractorRequirementForms.register()}
      {SubContractorWorkOrders.register()}
      {AgencyWorkOrders.register()}
      {SubContractorQuotations.register()}
      {AgencyQuotations.register()}
      {SubContractors.register()}
      {Agencies.register()}
      {Vendors.register()}
      {Reports.register()}
      {VendorQuotationComparisonLists.register()}
      {AgencyQuotationComparisonLists.register()}
      {SubContractorQuotationComparisonLists.register()}
      {RequiredTaskMaterials.register()}
      {SubContractorDebitNotes.register()}
      {SubContractorExtraWorks.register()}
      {SubContractorCreditNotes.register()}
      {VendorDebitNotes.register()}
      {VendorExtraWorks.register()}
      {VendorCreditNotes.register()}
      {AgencyDebitNotes.register()}
      {AgencyExtraWorks.register()}
      {AgencyCreditNotes.register()}
      {InventorySettings.register()}
      {WorkGroups.register()}
      {WorkItems.register()}
      {WorkUnits.register()}
      {VendorMaterialReceivables.register()}
      {quotations.register()}
      {PaymentTracker.register()}

      <Route
        path={basePath}
        element={<Navigate to={`${basePath}/dashboard`} replace />}
      // element={<HomePage />}
      />

      <Route path={`cashflow-tracker-report`} element={<CashFlowTracker />} />
      <Route
        path={`cummulative-report`}
        element={<CummilativeReport />}
      />

      <Route path="*" element={<NotFound returnPath={basePath} />} />
    </Route>
  );
};
