import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Select } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { ReportDataTable } from "../../../../stocks/components";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const PaymentTracker = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [searchValues, setSearchValues] = useState({});
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");

    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState('')

    const fetchProjects = async () => {
        try {
            const response = await axios.get(`projects`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Projects",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setProjects(options);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`vendors`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Vendors",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setVendors(options);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchInvoices = async (params = null) => {
        try {
            setLoading(true);

            const queryObject = {
                populate: "*",
            };

            if (!_.isEmpty(params)) {
                queryObject["filters"] = params;
            }

            let res = await axios.get(`received-invoices?${qs.stringify(queryObject)}`);

            if (res?.data) {
                setData(
                    res?.data?.data?.map((item) => ({
                        id: item?.id,
                        ...item?.attributes,
                    }))
                );
            }

        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }


        // Update the state with the modified data
    };

    const refreshData = () => {
        onSearch();
    };



    const getColumnFilterProps = ({ dataIndex, references = {} }) => {
        if (typeof references?.fieldName === "function") {
            const transformer = references?.fieldName;

            return {
                render: (data) => transformer(data?.data?.attributes) || "N/A",
            };
        } else {
            return {
                render: (data) =>
                    data?.data?.attributes?.[references?.fieldName] || "N/A",
            };
        }
    };

    const getColumnProps = (column) => {
        if (column?.references) {
            return getColumnFilterProps(column);
        }

        return column;
    };

    const onSearch = () => {
        let filters = [
            {
                $or: [
                    {
                        heading: {
                            $containsi: searchValues?.keyword?.toLowerCase(),
                        },
                    },
                    {
                        remarks: {
                            $containsi: searchValues?.keyword?.toLowerCase(),
                        },
                    },
                    // {
                    //   "vendor.data.attributes.name": {
                    //     $containsi: searchValues?.keyword?.toLowerCase(),
                    //   },
                    // },
                ],
            }
        ];

        if (searchValues?.start_date) {
            filters[0].$or.push({
                createdAt: {
                    $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                    $lte: searchValues?.end_date
                        ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
                        : undefined, // If end_date exists, format it, otherwise it's ignored
                },
            });
        }


        if (selectedProject !== "") {
            filters.push({
                project: selectedProject,
            });
        }

        if (selectedVendor !== "") {
            filters.push({
                vendor: selectedVendor,
            });
        }

        fetchInvoices({
            $and: filters,
        });
    };

    const onReset = () => {
        fetchInvoices()
    };

    const exportToExcel = async (data_array) => {
        // Create a new workbook instance using ExcelJS
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet with the name 'Budget' to the workbook
        const worksheet = workbook.addWorksheet('Budget');

        // Define the headers for the worksheet columns.
        worksheet.columns = [
            { header: 'Sr No.', key: 'sr', width: 10 },
            // { header: 'Po No.', key: 'id', width: 10 }, // commented out, as in your code snippet
            { header: 'Vendor Name', key: 'vendor_name', width: 25 },
            { header: 'Sub Milestone', key: 'submilestone', width: 25 },
            { header: 'Bill Date', key: 'invoice_date', width: 15 },
            { header: 'Invoice number', key: 'no', width: 15 },
            { header: 'Invoice Type', key: 'invoice_type', width: 15 },
            { header: 'Taxable Amount', key: 'taxable_amount', width: 15 },
            { header: 'Total GST Amount', key: 'tax_amount', width: 15 },
            { header: 'Total Bill with GST', key: 'grand_total', width: 15 },
            { header: 'Date of Handover to project', key: 'handover_date', width: 15 },
            { header: 'Status', key: 'status', width: 20 },
        ];

        /************************************************************************************
         * Style the header row (row 1) with a different background and font color, then
         * insert a blank row to create spacing beneath the heading.
         ************************************************************************************/
        const heading_row = worksheet.getRow(1);

        heading_row.eachCell((cell) => {
            // Bold white text on a dark blue background, centered
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF1F4E78' }, // Dark blue color in ARGB format
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Insert a blank row right after the heading to visually separate it from data rows
        worksheet.addRow([]);

        // Map data into rows
        data_array?.forEach((item, i) => {
            // Gather "normal" data
            // Extract vendor details from the item, defaulting to an empty object if missing
            const vendor_details = item?.vendor?.data?.attributes || {};

            /********************************************************************************
             * For each item, we compute cost details based on the "reconciliation" array.
             * We multiply (amount_percentage / 100) by (amount * received_quantity) for total cost.
             * Then compute CGST, SGST, IGST amounts, total tax, and grand total.
             ********************************************************************************/
            const items_cost = item?.reconciliation?.map((cost) => {
                // total_cost: (amount_percentage% of the total) * (amount * received_quantity)
                const total_cost = (Number(cost?.amount_percentage) / 100) * (cost.amount * cost.received_quantity);
                const cgst_amount = isNaN((cost.cqst / 100) * total_cost) ? 0 : (cost.cqst / 100) * total_cost;
                const sgst_amount = isNaN((cost.sqst / 100) * total_cost) ? 0 : (cost.sqst / 100) * total_cost;
                const igst_amount = isNaN((cost.igst / 100) * total_cost) ? 0 : (cost.igst / 100) * total_cost;

                // Summation of all tax amounts
                const total_tax = cgst_amount + sgst_amount + igst_amount;
                // grand_total is the sum of total cost and total tax
                const grand_total = total_cost + total_tax;

                return {
                    ...cost,
                    total_cost: total_cost,
                    cgst_amount: cgst_amount,
                    sgst_amount: sgst_amount,
                    igst_amount: igst_amount,
                    total_tax: total_tax,
                    grand_total: grand_total,
                };
            });

            // For each item, the code picks items_cost[i] to fill row fields.
            // This might be correct if there's exactly one or matching index usage, but
            // if you have multiple reconciliation entries, consider adjusting logic accordingly.
            const row_data = {
                sr: i + 1,
                // id: item?.id || '',
                vendor_name: vendor_details.name || '',
                submilestone: items_cost?.[i]?.task?.data?.attributes?.sub_milestone?.sub_milestone || '',
                invoice_date: item?.invoice_date || '',
                no: item?.no || '',
                invoice_type: item?.invoice_type || '',
                taxable_amount: items_cost?.[i]?.total_cost,
                tax_amount: items_cost?.[i]?.total_tax,
                grand_total: items_cost?.[i]?.grand_total,
                handover_date: moment(item?.handover_date).format('YYYY-MM-DD'),
                status: item?.status || '',
            };

            // Add a single row for this item
            worksheet.addRow(row_data);
        });

        // Save Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'payment-tracker.xlsx');
    };

    const getResultsMessage = () => {
        if (!_.isEmpty(searchValues)) {
            return total > 0 ? (
                <span>
                    <strong>{total}</strong> matched results
                </span>
            ) : (
                <span>No matched results</span>
            );
        }

        return (
            <span>
                <strong>{total}</strong> total records
            </span>
        );
    };

    useEffect(() => {
        fetchInvoices()
        fetchProjects();
        fetchVendor();
    }, []);

    console.log('Payment Trackers', data)

    return (
        <ReportDataTable
            data={data}
            config={{
                ...config,
                columns: config?.columns?.map((column) => ({
                    ...column,
                    ...getColumnProps(column),
                })),
            }}
            loading={loading}
            pagination={pagination}
            actions={{
                onRefresh: refreshData,
            }}
            custom_excel_report={() => exportToExcel(data)}

            Toolbar={
                <div className="toolbar">
                    <Row gutter={16}>
                        <Col span={10}>
                            <Input
                                value={searchValues?.keyword}
                                placeholder="Search for keyword..."
                                onChange={(e) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        keyword: e.target.value,
                                    }))
                                }
                            />
                        </Col>

                        <Col span={7}>
                            <DatePicker
                                onChange={(value) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        start_date: value,
                                    }))
                                }
                                value={searchValues?.start_date}
                                placeholder="Select start date"
                                style={styles.datePicker}
                            />
                        </Col>

                        <Col span={7}>
                            <DatePicker
                                onChange={(value) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        end_date: value,
                                    }))
                                }
                                value={searchValues?.endDate}
                                placeholder="Select end date"
                                style={styles.datePicker}
                            />
                        </Col>

                        <Col span={7}>
                            <Select
                                value={selectedProject}
                                onChange={setSelectedProject}
                                options={projects}
                                style={{ width: "100%", marginTop: '20px' }}
                                placeholder="Select Project"
                            />
                        </Col>
                        <Col span={7}>
                            <Select
                                value={selectedVendor}
                                onChange={setSelectedVendor}
                                options={vendors}
                                style={{ width: "100%", marginTop: '20px' }}
                                placeholder="Select Project"
                            />
                        </Col>
                    </Row>

                    <div className="toolbar-buttons">
                        <p className="toolbar-results">{getResultsMessage()}</p>

                        <Space>
                            <Button onClick={onReset}>Reset</Button>
                            <Button type="primary" onClick={onSearch}>
                                Search
                            </Button>
                        </Space>
                    </div>
                </div>
            }
            buttons={[
                <Tooltip placement="top" title="Refresh">
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={refreshData}
                        loading={loading}
                    />
                </Tooltip>,

            ]}
            onBack={() => navigate(`/ quick - stocks / reports`)}
        />
    );
};

const styles = {
    datePicker: {
        width: "100%",
    },
};

export default PaymentTracker;
