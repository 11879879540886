import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Select } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { ReportDataTable } from "../../../../stocks/components";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const VendorMasterData = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [updatedData, setUpdatedData] = useState([])
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [searchValues, setSearchValues] = useState({});
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");

    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState('')

    const fetchProjects = async () => {
        try {
            const response = await axios.get(`projects`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Projects",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setProjects(options);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`vendors`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Vendors",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setVendors(options);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = async (params = null) => {
        try {
            setLoading(true);

            const queryObject = {
                populate: "*",
                sort: ["estimated_amount:desc"],
            };

            if (!_.isEmpty(params)) {
                queryObject["filters"] = params;
            }

            const response = await axios.get(
                `vendor-quotations?${qs.stringify(queryObject)}`
            );

            // const response = await axios.get(
            //     `received-invoices?${qs.stringify(queryObject)}`
            // );

            if (response?.data) {
                setData(
                    response?.data?.data?.map((item) => ({
                        id: item?.id,
                        ...item?.attributes,
                    }))
                );

                setTotal(response?.data?.meta?.pagination?.total);

                setPagination({
                    ...response?.data?.meta?.pagination,
                    pageSize: 10,
                });
            };

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchInvoices = async () => {
        const updatedData = [...data]; // Create a copy of the current state

        for (let i = 0; i < updatedData.length; i++) {
            const queryObject = {
                populate: "*",
                filters: {
                    project: updatedData[i]?.project?.data?.id,
                    vendor: updatedData[i]?.vendor?.data?.id,
                }
            };

            try {
                let res = await axios.get(`received-invoices?${qs.stringify(queryObject)}`);

                const invoice = res?.data?.data;

                console.log('invoces', invoice);

                if (invoice?.length > 0) {
                    // Assuming you want to inject data from the first matching invoice
                    const matchingInvoice = invoice[invoice.length - 1];

                    console.log('ressssss', matchingInvoice)
                    // Update the corresponding item in the copied data array
                    const invoices_data = invoice.map(invoice_item => {
                        return {
                            // Amount payable for the invoice
                            amount_payable: invoice_item?.attributes?.amount_payable,
                            // Date of the invoice issuance
                            invoice_date: invoice_item?.attributes?.invoice_date,
                            // Invoice number from the invoice attributes
                            invoice_number: invoice_item?.attributes?.no,
                            // Type of the invoice (e.g., Tax Invoice)
                            invoice_type: invoice_item?.attributes?.invoice_type,
                            // Reconciliation details associated with the invoice
                            reconciliation: invoice_item?.attributes?.reconciliation,
                            // Payment schedule details linked to the invoice
                            payment_schedule: invoice_item?.attributes?.payment_schedule,
                            // Status of the invoice (e.g., Released)
                            invoice_status: invoice_item?.attributes?.status,
                            // The date when the invoice was handed over or created
                            handover_date: invoice_item?.attributes?.createdAt,
                            // Data for the project in-charge (approved_by information)
                            project_incharge: invoice_item?.attributes?.approved_by?.data,
                            // Additional status information; if no status is provided, default to an empty string
                            status: invoice_item?.attributes?.status ?? ''
                        };
                    });

                    updatedData[i] = {
                        ...updatedData[i],
                        invoices: invoices_data  // Adding all invoices to the updated item
                    };
                }
            } catch (error) {
                console.error('Error fetching invoices:', error);
            }
        }

        // Update the state with the modified data
        setUpdatedData(updatedData);
    };

    useEffect(() => {
        fetchInvoices();
    }, [data]); // Run once on component mount

    const refreshData = () => {
        onSearch();
    };



    const getColumnFilterProps = ({ dataIndex, references = {} }) => {
        if (typeof references?.fieldName === "function") {
            const transformer = references?.fieldName;

            return {
                render: (data) => transformer(data?.data?.attributes) || "N/A",
            };
        } else {
            return {
                render: (data) =>
                    data?.data?.attributes?.[references?.fieldName] || "N/A",
            };
        }
    };

    const getColumnProps = (column) => {
        if (column?.references) {
            return getColumnFilterProps(column);
        }

        return column;
    };

    const onSearch = () => {
        let filters = [
            {
                $or: [
                    {
                        heading: {
                            $containsi: searchValues?.keyword?.toLowerCase(),
                        },
                    },
                    {
                        remarks: {
                            $containsi: searchValues?.keyword?.toLowerCase(),
                        },
                    },
                    // {
                    //   "vendor.data.attributes.name": {
                    //     $containsi: searchValues?.keyword?.toLowerCase(),
                    //   },
                    // },
                ],
            }
        ];

        if (searchValues?.start_date) {
            filters[0].$or.push({
                createdAt: {
                    $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
                    $lte: searchValues?.end_date
                        ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
                        : undefined, // If end_date exists, format it, otherwise it's ignored
                },
            });
        }


        if (selectedProject !== "") {
            filters.push({
                project: selectedProject,
            });
        }

        if (selectedVendor !== "") {
            filters.push({
                vendor: selectedVendor,
            });
        }

        fetchData({
            $and: filters,
        });
    };

    const onReset = () => {
        setSearchValues({});
        fetchData(null);
    };

    // Export function that creates an Excel file from data using ExcelJS.
    // This version has been updated to handle multiple invoices per item.
    const exportToExcel = async (data_array) => {
        // Create a new workbook instance using ExcelJS
        const workbook = new ExcelJS.Workbook();

        // Add a worksheet with the name 'Budget' to the workbook
        const worksheet = workbook.addWorksheet('Budget');

        // Define the headers for the worksheet columns.
        worksheet.columns = [
            { header: 'Sr No.', key: 'sr', width: 10 },
            { header: 'Po No.', key: 'id', width: 10 },
            { header: 'Vendor Name', key: 'vendor_name', width: 25 },
            { header: 'Sub Milestone', key: 'submilestone', width: 25 },
            { header: 'Bill Date', key: 'invoice_date', width: 15 },
            { header: 'Invoice number', key: 'invoice_number', width: 15 },
            { header: 'Invoice Type', key: 'invoice_type', width: 15 },
            { header: 'Taxable Amount', key: 'taxable_amount', width: 15 },
            { header: 'Total GST Amount', key: 'tax_amount', width: 15 },
            { header: 'Total Bill with GST', key: 'grand_total', width: 15 },
            { header: 'Date of Handover to project', key: 'handover_date', width: 15 },
            { header: 'Project Incharge', key: 'project_incharge', width: 20 },
            { header: 'Status', key: 'status', width: 20 },
        ];

        /******************************************************************************
         * Style the header row (row 1) with a different background and font color,
         * then insert a blank row to create spacing beneath the heading.
         ******************************************************************************/
        const heading_row = worksheet.getRow(1);
        heading_row.eachCell((cell) => {
            // Example styling: bold white text on a dark blue background, center alignment
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF1F4E78' }, // A dark blue color
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Insert a blank row right after the heading to visually separate it from data rows
        worksheet.addRow([]);

        // Use a serial number to label rows
        let row_counter = 1;

        // Loop through the data
        data_array?.forEach((_item, _item_index) => {
            // Extract vendor details
            const vendor = _item?.vendor?.data?.attributes || {};

            // If the current item has invoices
            if (_item?.invoices && Array.isArray(_item.invoices) && _item.invoices.length > 0) {
                // Loop through each invoice in the invoices array
                _item.invoices.forEach((_invoice) => {
                    /************************************************************************
                     * Compute cost details from the invoice reconciliation.
                     * We'll map through each item in the "reconciliation" array and calculate:
                     *  1) total_cost (amount * quantity)
                     *  2) cgst_amount, sgst_amount, igst_amount (based on tax percentages)
                     *  3) total_tax = sum of the above tax amounts
                     *  4) grand_total = total_cost + total_tax
                     ************************************************************************/
                    const items_cost = _invoice?.reconciliation?.map((_recon) => {
                        const total_cost = _recon.amount * _recon.quantity;
                        const cgst_amount = isNaN((_recon.cqst / 100) * total_cost) ? 0 : (_recon.cqst / 100) * total_cost;
                        const sgst_amount = isNaN((_recon.sqst / 100) * total_cost) ? 0 : (_recon.sqst / 100) * total_cost;
                        const igst_amount = isNaN((_recon.igst / 100) * total_cost) ? 0 : (_recon.igst / 100) * total_cost;
                        const total_tax = cgst_amount + sgst_amount + igst_amount;
                        const grand_total = total_cost + total_tax;

                        return {
                            ..._recon,
                            total_cost: total_cost,
                            cgst_amount: cgst_amount,
                            sgst_amount: sgst_amount,
                            igst_amount: igst_amount,
                            total_tax: total_tax,
                            grand_total: grand_total
                        };
                    });

                    // By default, just use the first set of cost details from the array if it exists
                    const cost_details = items_cost && items_cost.length > 0 ? items_cost[0] : {};

                    // Prepare the row data for this invoice
                    const row_data = {
                        sr: row_counter,
                        id: _item?.id || '',
                        vendor_name: vendor.name || '',
                        submilestone: _item?.task?.data?.attributes?.sub_milestone?.sub_milestone || '',
                        invoice_date: _invoice?.invoice_date || '',
                        invoice_number: _invoice?.invoice_number || '',
                        invoice_type: _invoice?.invoice_type || '',
                        taxable_amount: cost_details?.total_cost || '',
                        tax_amount: cost_details?.total_tax || '',
                        grand_total: cost_details?.grand_total || '',
                        handover_date: _invoice?.handover_date
                            ? moment(_invoice.handover_date).format('YYYY-MM-DD')
                            : '',
                        project_incharge: _invoice?.project_incharge
                            ? `${_invoice.project_incharge?.attributes?.first_name || ''} ${_invoice.project_incharge?.attributes?.last_name || ''}`
                            : '',
                        status: _invoice?.status === 'Approved' ? '' : _invoice?.status || '',
                    };

                    worksheet.addRow(row_data);
                    row_counter++;
                });
            } else {
                // If there are no invoices, we can still fill a row with non-invoice data
                // using the parent's reconciliation if any
                const items_cost = _item?.reconciliation?.map((_recon) => {
                    const total_cost = _recon.amount * _recon.quantity;
                    const cgst_amount = isNaN((_recon.cqst / 100) * total_cost) ? 0 : (_recon.cqst / 100) * total_cost;
                    const sgst_amount = isNaN((_recon.sqst / 100) * total_cost) ? 0 : (_recon.sqst / 100) * total_cost;
                    const igst_amount = isNaN((_recon.igst / 100) * total_cost) ? 0 : (_recon.igst / 100) * total_cost;
                    const total_tax = cgst_amount + sgst_amount + igst_amount;
                    const grand_total = total_cost + total_tax;
                    return {
                        ..._recon,
                        total_cost: total_cost,
                        cgst_amount: cgst_amount,
                        sgst_amount: sgst_amount,
                        igst_amount: igst_amount,
                        total_tax: total_tax,
                        grand_total: grand_total
                    };
                });

                const cost_details = items_cost && items_cost.length > 0 ? items_cost[0] : {};

                // Prepare the row data without invoice-specific fields
                const row_data = {
                    sr: row_counter,
                    id: _item?.id || '',
                    vendor_name: vendor.name || '',
                    submilestone: _item?.task?.data?.attributes?.sub_milestone?.sub_milestone || '',
                    invoice_date: '',
                    invoice_number: '',
                    invoice_type: '',
                    taxable_amount: cost_details?.total_cost || '',
                    tax_amount: cost_details?.total_tax || '',
                    grand_total: cost_details?.grand_total || '',
                    handover_date: _item?.handover_date
                        ? moment(_item.handover_date).format('YYYY-MM-DD')
                        : '',
                    project_incharge: '',
                    status: _item?.status === 'Approved' ? '' : _item?.status || '',
                };

                worksheet.addRow(row_data);
                row_counter++;
            }
        });

        // Finally, write the workbook data to a buffer, create a Blob, and save
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'vendor-masterdata.xlsx');
    };



    const getResultsMessage = () => {
        if (!_.isEmpty(searchValues)) {
            return total > 0 ? (
                <span>
                    <strong>{total}</strong> matched results
                </span>
            ) : (
                <span>No matched results</span>
            );
        }

        return (
            <span>
                <strong>{total}</strong> total records
            </span>
        );
    };

    useEffect(() => {
        fetchData(filters);
        fetchProjects();
        fetchVendor();
    }, []);

    console.log('Vendors Ledgers', data, updatedData)

    return (
        <ReportDataTable
            data={data}
            config={{
                ...config,
                columns: config?.columns?.map((column) => ({
                    ...column,
                    ...getColumnProps(column),
                })),
            }}
            loading={loading}
            pagination={pagination}
            actions={{
                onAdd: () => navigate(`/ quick - stocks / ${config?.slugs?.plural} / add`),
                onRefresh: refreshData,
            }}
            custom_excel_report={() => exportToExcel(updatedData)}

            Toolbar={
                <div className="toolbar">
                    <Row gutter={16}>
                        <Col span={10}>
                            <Input
                                value={searchValues?.keyword}
                                placeholder="Search for keyword..."
                                onChange={(e) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        keyword: e.target.value,
                                    }))
                                }
                            />
                        </Col>

                        <Col span={7}>
                            <DatePicker
                                onChange={(value) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        start_date: value,
                                    }))
                                }
                                value={searchValues?.start_date}
                                placeholder="Select start date"
                                style={styles.datePicker}
                            />
                        </Col>

                        <Col span={7}>
                            <DatePicker
                                onChange={(value) =>
                                    setSearchValues((prev) => ({
                                        ...prev,
                                        end_date: value,
                                    }))
                                }
                                value={searchValues?.endDate}
                                placeholder="Select end date"
                                style={styles.datePicker}
                            />
                        </Col>

                        <Col span={7}>
                            <Select
                                value={selectedProject}
                                onChange={setSelectedProject}
                                options={projects}
                                style={{ width: "100%", marginTop: '20px' }}
                                placeholder="Select Project"
                            />
                        </Col>
                        <Col span={7}>
                            <Select
                                value={selectedVendor}
                                onChange={setSelectedVendor}
                                options={vendors}
                                style={{ width: "100%", marginTop: '20px' }}
                                placeholder="Select Project"
                            />
                        </Col>
                    </Row>

                    <div className="toolbar-buttons">
                        <p className="toolbar-results">{getResultsMessage()}</p>

                        <Space>
                            <Button onClick={onReset}>Reset</Button>
                            <Button type="primary" onClick={onSearch}>
                                Search
                            </Button>
                        </Space>
                    </div>
                </div>
            }
            buttons={[
                <Tooltip placement="top" title="Refresh">
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={refreshData}
                        loading={loading}
                    />
                </Tooltip>,

            ]}
            onBack={() => navigate(`/ quick - stocks / reports`)}
        />
    );
};

const styles = {
    datePicker: {
        width: "100%",
    },
};

export default VendorMasterData;
