import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input, Select, Row, Col } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import _ from "lodash";
import { ReloadOutlined } from "@ant-design/icons";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Toast, ToastProvider } from "@chakra-ui/react";
import { axios } from "../../../../../../App";
import { ReportDataTable } from "../../../../components";

const CummulativeReport = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [searchValues, setSearchValues] = useState({});
    const [materialGroupId, setMaterialGroupId] = useState("");
    const [values, setValues] = useState({
        procurement: [
            {
                value: 'agency-quotations',
                label: 'Agency',
            },
            {
                value: 'sub-contractor-quotations',
                label: 'Sub Contractor',
            },
            {
                value: 'vendor-quotations',
                label: 'Vendor',
            },
        ]
    });

    const [procurement, setProcurement] = useState('')
    const location = useLocation();

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");

    const fetchProjects = async () => {
        try {
            const response = await axios.get(`projects`);

            if (response?.data) {
                let options = [
                    {
                        value: "",
                        label: "All Projects",
                    },
                ];

                response?.data?.data?.forEach((record) => {
                    options?.push({
                        value: record?.id,
                        label: record?.attributes?.name,
                    });
                });

                setProjects(options);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = async (params = null) => {
        try {
            setLoading(true);

            const queryObject = {
                populate: "*",
                sort: ["estimated_amount:desc"],
            };


            if (!_.isEmpty(params)) {
                queryObject["filters"] = params;
            }

            const response = await axios.get(
                `${procurement}?${qs.stringify(queryObject)}`
            );

            if (response?.data) {
                // Using Promise.all to handle async operations within map
                const fetchInvoices = async () => {
                    const mappedData = await Promise.all(response.data.data.map(async (item) => {
                        const obj = {
                            populate: '*',
                            filters: {
                                status: "Released",
                                project: item?.attributes?.project?.data?.id,
                                vendor: item?.attributes?.vendor?.data?.id,
                                sub_contractor: item?.attributes?.sub_contractor?.data?.id,
                                agency: item?.attributes?.agency?.data?.id
                            }
                        };

                        try {
                            // Awaiting the axios call
                            const invoice_res = await axios.get(
                                `received-invoices?${qs.stringify(obj)}`
                            );

                            const company_res = await axios.get(`/projects/${item?.attributes?.project?.data?.id}?populate=*`);

                            const meterial_and_payments = item?.attributes?.material_item_notes ?
                                item?.attributes?.material_item_notes?.map((note) => ({
                                    ...note,
                                    payments: item?.attributes?.payment_schedules?.filter((payment) => payment?.material_group?.data?.id == note?.material_group
                                    ),
                                    received_invoice: invoice_res?.data?.data?.find((invoice) => invoice?.attributes?.reconciliation?.[0]?.material_group == note?.material_group),
                                }))

                                :

                                item?.attributes?.work_item_notes?.map((note) => ({
                                    ...note,
                                    payments: item?.attributes?.payment_schedules?.filter((payment) => payment?.material_group?.data?.id == note?.material_group
                                    ),
                                    received_invoice: invoice_res?.data?.data?.find((invoice) => invoice?.attributes?.reconciliation?.[0]?.material_group == note?.material_group),
                                }))


                            return {
                                id: item?.id,
                                procurement: item.attributes?.vendor?.data ?? item.attributes?.sub_contractor?.data,
                                company: company_res?.data?.data?.attributes?.company,
                                meterial_and_payments: meterial_and_payments,
                                ...item?.attributes,
                            };
                        } catch (error) {
                            console.error("Error fetching invoices:", error);
                            return {
                                id: item?.id,
                                procurement: item.attributes?.vendor?.data ?? item.attributes?.sub_contractor?.data,
                                ...item?.attributes,
                            };
                        }
                    }));

                    // Set the resolved data to state
                    setData(mappedData);

                    setTotal(response?.data?.meta?.pagination?.total);

                    setPagination({
                        ...response?.data?.meta?.pagination,
                        pageSize: 10,
                    });
                };

                // Invoke the async function
                fetchInvoices();

            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const refreshData = () => {
        onSearch();
    };



    const getColumnFilterProps = ({ dataIndex, references = {} }) => {
        if (typeof references?.fieldName === "function") {
            const transformer = references?.fieldName;

            return {
                render: (data) => transformer(data?.data?.attributes) || "N/A",
            };
        } else {
            return {
                render: (data) =>
                    data?.data?.attributes?.[references?.fieldName] || "N/A",
            };
        }
    };

    const getColumnProps = (column) => {
        if (column?.references) {
            return getColumnFilterProps(column);
        }

        return column;
    };

    const onSearch = () => {
        let orConditions = {
            $or: [
                {
                    heading: {
                        $containsi: searchValues?.keyword?.toLowerCase(),
                    },
                },
            ]
        }

        let searchParams = orConditions;

        if (materialGroupId !== "") {
            searchParams = {
                $and: [
                    {
                        task: parseInt(materialGroupId),
                    },
                    orConditions,
                ],
            };
        }

        if (selectedProject !== "") {
            searchParams = {
                $and: [
                    {
                        project: selectedProject,
                    },
                    orConditions,
                ],
            };
        }

        fetchData(searchParams);
    };


    const get_previous_quantity_for_item = (item, item_name) => {

        if (
            !item?.received_invoice?.attributes?.reconciliation ||
            !Array.isArray(item.received_invoice?.attributes?.reconciliation)
        ) {
            return 0;
        }
        // Attempt to find a matching reconciliation record for that item_name
        // We'll just assume there's either zero or one match in your data:
        const match = item.received_invoice?.attributes?.reconciliation.find(
            (rec) => rec.material_item === item_name
        );
        if (match && match.received_quantity) {
            return Number(match.received_quantity);
        }
        return 0;
    };

    // Sum the amount_percentage from the payments array on a given line item
    // If "payments" is missing or empty, this returns 0. Otherwise sum the "amount_percentage".
    const get_total_released_percentage = (line_item) => {
        if (!line_item?.received_invoice?.attributes?.reconciliation || !Array.isArray(line_item?.received_invoice?.attributes?.reconciliation)) return 0;
        return line_item?.received_invoice?.attributes?.reconciliation.reduce((acc, p) => {
            // p.amount_percentage might be a string, so parse it:
            const val = Number(p.amount_percentage) || 0;
            return acc + val;
        }, 0);
    };

    // Create a function that triggers the Excel generation
    const exportToExcel = async (data) => {
        // Create a new workbook
        const workbook = new ExcelJS.Workbook();

        // Some workbook props (optional)
        workbook.creator = "My React App";
        workbook.lastModifiedBy = "My React App";
        workbook.created = new Date();
        workbook.modified = new Date();

        // Add a worksheet
        const worksheet = workbook.addWorksheet("Annexure Cumulative");

        /*
          We'll define column headers, but we also have merges and
          big titles at the top. So let's do the merges first for the
          big headings, then define column widths, then we'll fill
          row-by-row data.
    
          According to your screenshot:
    
            Row 1: "Annexure: Cumulative Work Performed by Vendor" 
            merged across columns A–O
        */
        worksheet.mergeCells("A1:O1");
        const title_cell = worksheet.getCell("A1");
        title_cell.value = `Annexure: Cumulative Work Performed by ${data?.vendor?.data?.attributes?.name}`;
        title_cell.font = { bold: true, size: 16 };
        title_cell.alignment = { vertical: "middle", horizontal: "center" };

        // Row 2: has the vendor name & address. We'll do a single merge again across columns A–O
        worksheet.mergeCells("A2:O2");
        const vendor_name_cell = worksheet.getCell("A2");
        // We can pick out the vendor name from the data; you have vendor->data->attributes->name
        const main_obj = data; // Because you said there's at least one object
        const vendor_name =
            main_obj?.vendor?.data?.attributes?.name || "Vendor Company Name";
        const vendor_address =
            main_obj?.vendor?.data?.attributes?.address_line_1 || "Vendor Address";

        vendor_name_cell.value = `M/s ${vendor_name} \nAddress: ${vendor_address}`;
        vendor_name_cell.font = { size: 12 };
        vendor_name_cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
        };

        // We also see there's a "Work order no" above columns, let's do that in row 3 merged A3:O3
        worksheet.mergeCells("A3:O3");
        const work_order_no_cell = worksheet.getCell("A3");
        work_order_no_cell.value = "Work order no";
        work_order_no_cell.font = { size: 12 };
        work_order_no_cell.alignment = {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
        };

        // Now let's define the column widths to somewhat match your screenshot
        // (You can tweak these to your liking)
        worksheet.getColumn("A").width = 6;  // Sr. No
        worksheet.getColumn("B").width = 40; // Description of Work
        worksheet.getColumn("C").width = 12; // Work order quantity
        worksheet.getColumn("D").width = 12; // BOQ Prev
        worksheet.getColumn("E").width = 12; // BOQ Current
        worksheet.getColumn("F").width = 12; // BOQ Cumulative
        worksheet.getColumn("G").width = 8;  // Unit
        worksheet.getColumn("H").width = 12; // Rate / Unit
        worksheet.getColumn("I").width = 16; // As per W.O. Terms Rate
        worksheet.getColumn("J").width = 16; // STAGE
        worksheet.getColumn("K").width = 12; // Amount in Rs. (Previous)
        worksheet.getColumn("L").width = 12; // Amount in Rs. (Current)
        worksheet.getColumn("M").width = 14; // Amount in Rs. (Cumulative)
        worksheet.getColumn("N").width = 14; // Balance quantity
        worksheet.getColumn("O").width = 12; // Current ERP QTY ?

        // Next we want a row for the column headings. 
        // The screenshot shows multiple row headings, but let's do a single row that includes everything:
        const header_row_values = [
            "Sr. No.",
            "Description of Work",
            "Work order Qty",
            "BOQ Prev",
            "BOQ Current",
            "BOQ Cumulative",
            "Unit",
            "Rate/Unit",
            "As per W.O. Terms Rate",
            "STAGE",
            "Amount in Rs. (Prev)",
            "Amount in Rs. (Curr)",
            "Amount in Rs. (Cumul.)",
            "Balance W.O. Qty",
            "CURRENT ERP QTY",
        ];
        const header_row = worksheet.addRow(header_row_values);
        header_row.font = { bold: true };
        header_row.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
        };

        // We'll freeze the top rows so that header is visible on scroll
        worksheet.views = [
            { state: "frozen", xSplit: 0, ySplit: 4 } // freeze top 4 rows
        ];

        /*
          Now we loop over each "meterial_and_payments" item to produce table rows.
          Each row has: 
            A => index + 1 (Sr. no)
            B => item_description (or fallback to material_item)
            C => work order quantity (the 'quantity' field in your data)
            D => previous BOQ
            E => current BOQ
            F => cumulative = D+E
            G => unit (e.g. "Kg" or "Sq.ft" from item.material_unit)
            H => rate/unit (some formula? This is unclear in your specs. I'll just put the "estimated_budget / quantity" as an example.)
            I => sum of payment percentages
            J => a stage or some text (the screenshot had "SUPPLY", "INSTALLATION", "COMPLETION" etc. We can store "Multiple" or something.)
            K => previous amount in Rs. (the example might be "previous qty * rate")
            L => current amount in Rs. (current qty * rate)
            M => cumulative amount in Rs. 
            N => balance W.O. quantity = (C - F)? or your own formula
            O => current ERP QTY or some leftover from your screenshot
    
          Adjust these formulas to match your real logic and columns.
        */

        if (
            main_obj &&
            main_obj.meterial_and_payments &&
            Array.isArray(main_obj.meterial_and_payments)
        ) {
            main_obj.meterial_and_payments.forEach((item, idx) => {
                // figure out "previous"
                const material_name = item.material_item;
                const previous_qty = get_previous_quantity_for_item(item, material_name);
                const total_qty = Number(item.quantity) || 0;
                const current_qty = total_qty - previous_qty;
                const cumulative_qty = previous_qty + current_qty;

                console.log('get_previous_quantity_for_item', main_obj, item)

                // Rate/Unit (example)
                const rate_per_unit =
                    total_qty > 0
                        ? Number(item.amount) / total_qty
                        : 0;

                // "As per W.O. Terms Rate" = sum of item.payments[].amount_percentage
                const total_percentage = get_total_released_percentage(item);

                // Let's do a simple approach to "STAGE" 
                // We'll just store a combined string of all payment "name"
                let stage_text = "";
                if (Array.isArray(item.payments) && item.payments.length > 0) {
                    stage_text = item.payments.map((p) => p.name).join(", ");
                } else {
                    stage_text = "N/A";
                }

                // "Amount in Rs. (Prev)" = previous_qty * rate_per_unit
                const prev_amount_rs = item?.received_invoice?.attributes?.reconciliation.reduce((acc, note) => {
                    return acc + (note.amount * note.received_quantity);
                }, 0)

                // "Amount in Rs. (Current)" = current_qty * rate_per_unit
                const curr_amount_rs = item?.amount * item?.quantity
                // "Amount in Rs. (Cumulative)" = sum
                const cumul_amount_rs = prev_amount_rs + curr_amount_rs;

                // "Balance W.O. Qty" might be (C - F)? 
                const balance_wo_qty = total_qty - cumulative_qty; // often 0 if we've used them all

                // "Current ERP QTY" we can guess as current_qty, or your own logic
                const current_erp_qty = current_qty;

                // Description of Work can come from item_description if it exists
                const description_of_work = item.item_description
                    ? item.item_description.replace(/<[^>]+>/g, "") // strip HTML if you want
                    : item.material_item;

                const row_data = [
                    idx + 1,                         // A - Sr. No.
                    description_of_work,             // B - Description of Work
                    total_qty,                       // C - Work order Qty
                    previous_qty,                    // D - BOQ Previous
                    current_qty,                     // E - BOQ Current
                    cumulative_qty,                  // F - BOQ Cumulative
                    item.material_unit || "Unit",    // G - Unit
                    rate_per_unit.toFixed(2),        // H - Rate/Unit
                    `${total_percentage}%`,          // I - As per W.O. Terms Rate
                    stage_text,                      // J - STAGE
                    prev_amount_rs.toFixed(2),       // K - Amount in Rs. (Prev)
                    curr_amount_rs.toFixed(2),       // L - Amount in Rs. (Curr)
                    cumul_amount_rs.toFixed(2),      // M - Amount in Rs. (Cumul.)
                    balance_wo_qty,                  // N - Balance W.O. Qty
                    current_erp_qty,                 // O - Current ERP QTY
                ];

                const new_row = worksheet.addRow(row_data);
                new_row.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                };
            });
        }

        /*
          Finally, we can add a "TOTAL RS" row, signature row, etc. as shown in your screenshot.
          We'll do a quick example of merging columns for "TOTAL" at the bottom:
        */
        const last_row_number = worksheet.lastRow.number + 1;
        // worksheet.mergeCells(`A${last_row_number}:J${last_row_number}`);
        // worksheet.getCell(`A${last_row_number}`).value = "TOTAL RS";
        // worksheet.getCell(`A${last_row_number}`).font = { bold: true };
        // worksheet.getCell(`A${last_row_number}`).alignment = {
        //     horizontal: "right",
        // };
        // // We might sum up column K from the first data row to last data row
        // // If you want a formula, you can do:
        // worksheet.getCell(`K${last_row_number}`).value = {
        //     formula: `SUM(K${header_row.number + 1}:K${last_row_number - 1})`,
        // };
        // // Similarly for L, M, etc.
        // worksheet.getCell(`L${last_row_number}`).value = {
        //     formula: `SUM(L${header_row.number + 1}:L${last_row_number - 1})`,
        // };
        // worksheet.getCell(`M${last_row_number}`).value = {
        //     formula: `SUM(M${header_row.number + 1}:M${last_row_number - 1})`,
        // };

        // You could also merge the bottom signature rows, e.g. "A${x}:F${x}" for
        // "Authorized Signatory" etc. Here is an example:
        const sign_row_number = last_row_number + 2;
        worksheet.mergeCells(`J${sign_row_number}:O${sign_row_number}`);
        worksheet.getCell(`J${sign_row_number}`).value = "Authorised Signatory";
        worksheet.getCell(`J${sign_row_number}`).alignment = {
            horizontal: "center",
        };

        // Now we write the workbook to a blob and trigger download
        const buf = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buf], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Annexure_Cumulative_Work.xlsx");
    };

    const onReset = () => {
        setSearchValues({});
        fetchData({
            filters: {
                status: "Approved",
            },
        });
    };

    const getResultsMessage = () => {
        if (!_.isEmpty(searchValues)) {
            return total > 0 ? (
                <span>
                    <strong>{total}</strong> matched results
                </span>
            ) : (
                <span>No matched results</span>
            );
        }

        return (
            <span>
                <strong>{total}</strong> total records
            </span>
        );
    };

    useEffect(() => {
        const queryString = location?.search?.substring(1);

        if (queryString !== "") {
            const queryObject = qs.parse(queryString);

            if (queryObject?.taskId) {
                setMaterialGroupId(`${queryObject?.taskId}`);

                fetchData({
                    $and: [
                        {
                            task: parseInt(queryObject?.taskId),
                        },
                        {
                            status: "Approved",
                        },
                        {
                            project: selectedProject
                        }
                    ],
                });
            } else {
                fetchData({
                    status: "Approved",
                    project: selectedProject

                });
            }
        } else {
            fetchData({
                status: "Approved",
                project: selectedProject
            });

            fetchProjects()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.search]);


    console.log('Cummulative Report', data)

    return (

        <ReportDataTable
            data={data}
            config={{
                ...config,
                columns: config?.columns
                    ?.map((column) => ({
                        ...column,
                        ...getColumnProps(column),
                    }))
                    ?.concat({
                        title: "Actions",
                        width: 100,
                        selectable: false,
                        render: (item) => {
                            return (
                                <Space style={styles.row}>
                                    <Tooltip
                                        placement="top"
                                        title={`Download Report`}
                                    >
                                        <Button
                                            icon={<DownloadOutlined />}
                                            onClick={() => exportToExcel(item)}

                                        />
                                    </Tooltip>
                                </Space>
                            );
                        },
                    }),

            }}
            loading={loading}
            pagination={pagination}
            actions={{
                onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
                onRefresh: refreshData,
            }}
            Toolbar={
                <div className="toolbar">
                    <Row gutter={16}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <label htmlFor="">Select Procurement</label>
                            <Select
                                value={procurement}
                                onChange={setProcurement}
                                options={values?.procurement}
                                placeholder="Select a procurement"
                                style={{ width: "300px", marginTop: '10px' }}
                            />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                            <label htmlFor="">Select Project</label>
                            <Select
                                value={selectedProject}
                                onChange={setSelectedProject}
                                options={projects}
                                placeholder="Select Project"
                                style={{ width: "300px", marginTop: '10px' }}
                            />
                        </div>

                        <Input
                            value={searchValues?.keyword}
                            placeholder="Search for keyword..."
                            style={{ width: "100%", margin: '10px' }}
                            onChange={(e) =>
                                setSearchValues((prev) => ({
                                    ...prev,
                                    keyword: e.target.value,
                                }))
                            }
                        />

                    </Row>

                    <div className="toolbar-buttons">
                        <p className="toolbar-results">{getResultsMessage()}</p>

                        <Space>
                            <Button onClick={onReset}>Reset</Button>
                            <Button type="primary" onClick={onSearch}>
                                Search
                            </Button>
                        </Space>
                    </div>
                </div>
            }
            buttons={[
                <Tooltip placement="top" title="Refresh">
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={refreshData}
                        loading={loading}
                    />
                </Tooltip>,
            ]}
        />
    );
};

const styles = {
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
};

export default CummulativeReport;
