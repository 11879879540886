import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  Empty,
  Input,
  Row,
  Col,
  Button,
  PageHeader,
  Breadcrumb,
  Spin,
  Modal,
  Select,
  Table,
} from "antd";
import { axios } from "../../../../../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import qs from "qs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { STRAPI_URL } from "../../../../../constants";
import parse from "html-react-parser"
import { FaFileInvoice } from "react-icons/fa";

const { TextArea } = Input;
const { Option } = Select;


const Page = ({
  inputId = null,
  showsCancel = true,
  showsHeader = true,
  mode = "normal",
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();
  const config = makeConfig({ navigate });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [materialItemsRemarks, setMaterialItemsRemarks] = useState("");
  const [termsRemarks, setTermsRemarks] = useState("");
  const [paymentSchedulesRemarks, setPaymentSchedulesRemarks] = useState("");
  const [letterheadFileName, setLetterheadFileName] = useState(null);
  const [showsModal, setShowsModal] = useState(false);
  const [letterheads, setLetterheads] = useState([]);
  const [terms, setTerms] = useState([]);
  const [tableTerms, setTablTerms] = useState([])
  const [fileTerms, setFileTerms] = useState([])

  const fetchData = async (quotationId) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
      };

      const response = await axios.get(
        `/vendor-quotations/${quotationId}?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        const dataObject = response?.data?.data?.attributes;

        setData(dataObject);
        setMaterialItemsRemarks(dataObject?.material_items_remarks);
        setTermsRemarks(dataObject?.terms_remarks);
        setPaymentSchedulesRemarks(dataObject?.payment_schedules_remarks);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLetterheads = async () => {
    try {
      const resp = await axios.get(`/letterheads?populate=*`);

      if (resp?.data?.data?.length > 0) {
        const results = resp?.data?.data?.map((record, index) => {
          let fileName =
            record?.attributes?.image_file?.data?.attributes?.url?.split(
              "/"
            )[2] || "";

          return {
            id: record?.id,
            name: record?.attributes?.name,
            file_name: fileName,
          };
        });

        setLetterheads(results);
      }
    } catch (error) { }
  };

  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  const generatePdf = () => {
    setTimeout(() => {
      var element = componentRef?.current?.innerHTML;

      var opt = {
        margin: [0.03, 0, 0.3, 0],
        filename: "vendor_purchase_order" + ".pdf",
        image: { type: "jpeg", quality: 0.5 },
        pagebreak: { avoid: "tr", mode: "css", before: "#nextpage1" },
        html2canvas: {
          scale: 5,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a3", orientation: "portrait" },
        compressImage: false,
      };

      import("html2pdf.js").then((html2pdf) => {
        /* html2pdf().set(opt).from(element).save(); */
        html2pdf.default().set(opt).from(element).save();
      });
    }, 10);
  };

  const onSubmit = () => {
    setShowsModal(false);
    generatePdf();

  };

  const calculateAmount = (paymentSchedule, materialItemNotes) => {
    // Find the corresponding materialItemNote based on the material_group
    const materialItemNote = materialItemNotes?.find(
      (item) => item?.material_group === paymentSchedule?.material_group?.data?.id
    );

    console.log('calculating amount', materialItemNote, paymentSchedule);

    // If no corresponding materialItemNote is found, return 0
    if (!materialItemNote) {
      return 0;
    }

    // Calculate the base amount based on percentage
    const baseAmount =
      (Number(paymentSchedule.amount_percentage) / 100) *
      (materialItemNote.amount * materialItemNote.quantity);

    // Calculate tax amounts (assuming cqst and sqst are percentages)
    const cqstTax = (materialItemNote.cqst / 100) * baseAmount;
    const sqstTax = (materialItemNote.sqst / 100) * baseAmount;
    const igstTax = (materialItemNote.igst / 100) * baseAmount;

    // Calculate the total amount, including taxes
    const totalAmount = baseAmount + cqstTax ?? 0 + sqstTax ?? 0 + igstTax ?? 0;

    return totalAmount;
  };


  const total_cgst = data?.material_item_notes?.reduce((acc, item) => {
    return acc + item?.cqst; // Add budget * quantity to the accumulator
  }, 0);

  const total_sgst = data?.material_item_notes?.reduce((acc, item) => {
    return acc + item?.sqst; // Add budget * quantity to the accumulator
  }, 0);

  const total_igst = data?.material_item_notes?.reduce((acc, item) => {
    return acc + item?.igst; // Add budget * quantity to the accumulator
  }, 0);

  const basic_contract_value = data?.material_items?.reduce((acc, item) => {
    const budget = parseFloat(item.estimated_budget) || 0; // Convert estimated_budget to a number
    const quantity = parseFloat(item.quantity) || 0; // Convert quantity to a number
    return acc + (budget * quantity); // Add budget * quantity to the accumulator
  }, 0);

  const total_cgst_value = basic_contract_value * (total_cgst / 100);
  const total_sgst_value = basic_contract_value * (total_sgst / 100);
  const total_igst_value = basic_contract_value * (total_igst / 100);

  const columns = [
    {
      title: 'Term Name',
      dataIndex: 'table_term_name',
      render: (_, record) => (
        <Input
          value={record.table_term_name}
          onChange={(e) => handleInputChange(e, record.id, 'table_term_name')}
          placeholder="Enter term name"
          disabled
        />
      )
    },
    {
      title: 'Term Details',
      dataIndex: 'table_term_details',
      render: (_, record) => (
        <Input.TextArea
          value={record.table_term_details}
          onChange={(e) => handleInputChange(e, record.id, 'table_term_details')}
          placeholder="Enter term details"
          autoSize={{ minRows: 1, maxRows: 8 }} // Adjusts height dynamically from 1 to 5 rows
        />
      )
    },
  ];

  const fileUploadColumns = [
    {
      title: 'Term Name',
      dataIndex: 'details',
      render: (_, record) => (
        <Input
          value={record.details}
          onChange={(e) => handleInputFileChange(e, record.id, 'details')}
          placeholder="Enter term name"
        />
      )
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'file',
      render: (_, record) => {
        return (
          <div>
            <Input
              className="hide-in-pdf"
              type="file"
              onChange={(e) => onFileChange(e, record, 'remark')}
              style={{ marginBottom: '5px' }}
            />
            {record.remark && (
              <a
                href={`${STRAPI_URL}${record?.remark?.url}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <FaFileInvoice style={{ fontSize: '20px', color: 'red' }} />
                {record?.remark?.name}
              </a>
            )}
          </div>
        )
      },
    },
  ];


  // State to hold filtered material items for each boq item
  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    setTablTerms((prevTerms) =>
      prevTerms.map((term) =>
        term.id === id ? { ...term, [field]: value } : term
      )
    );
  };

  const onFileChange = async (e, record, field) => {
    try {
      const file = e.target.files?.[0];
      let fileData = new FormData();
      fileData.append('files', file);

      const response = await axios.post('/upload', fileData);

      if (response?.data?.length > 0) {
        const uploadedFile = response.data[0];

        // setTerms((prevState) => {
        //   return {
        //     ...prevState, // Spread the existing `terms` object
        //     terms: prevState.terms.map((term) =>
        //       term.id === record.id ? { ...term, file: uploadedFile } : term
        //     ), // Update the nested `terms` array
        //   };
        // });

        setFileTerms((prevTerms) =>
          prevTerms.map((term) =>
            term.id === record?.id ? { ...term, [field]: uploadedFile } : term
          )
        );

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputFileChange = (e, id, field) => {
    const { value } = e.target;
    setFileTerms((prevTerms) =>
      prevTerms.map((term) =>
        term.id === id ? { ...term, [field]: value } : term
      )
    );
  };



  useEffect(() => {
    if (basic_contract_value > 0) {
      setTimeout(() => {
        setTerms((prevState) => ({
          ...prevState,
          terms: [
            ...(Array.isArray(prevState?.terms) ? prevState.terms : []), // Ensure terms is an array
            {
              term_name: 'Financials:',
              term_details: `
                        <div>
                            <h3>1 Contract Amount:</h3>
                            <p>
                                This agreement amount shall include all transportation, loading and unloading, scaffolding, consumables, 
                                all lead and lift resources and tackles, Taxes, cesses, duties, cargo, damages, Safety measures and 
                                Insurance, all overheads and profits additionally the labour, machinery, fuel, mathadi, etc. total.
                            </p>
                            <p>
                                <strong>Basic Value of Contract:</strong> &nbsp;&nbsp;&nbsp; ${basic_contract_value}/-
                            </p>
                            <p>
                                <strong>Total GST Amount:</strong> &nbsp;&nbsp;&nbsp;
                                ${total_cgst > 0 ? `${total_cgst_value}/- CGST` : ''}
                                ${total_sgst > 0 ? `+ ${total_sgst_value}/- SGST` : ''}
                                ${total_igst > 0 ? `+ ${total_igst_value}/- IGST` : ''}
                            </p>
                            <p>
                                <strong>Total Contract Amount:</strong> &nbsp;&nbsp;&nbsp; ${data?.grand_total_amount}/-
                            </p>

                            <h3>2 Taxes:</h3>
                            <p>
                                GST/Relevant Tax Registration number needs to be clearly mentioned in the contractor's bill. 
                                The Contractor shall provide all the documentary details as may be required by the client. 
                                The Contractor is solely accountable for payment of taxes with regard to the Scope of Work and this work order. 
                                Taxes as applicable shall be paid as per government rules & regulations. T.D.S shall be deducted from each R.A. bill.
                            </p>
                            <p>
                                The Client shall issue a TDS consolidated certificate on a quarterly basis upon request by the Contractor to the Client in writing.
                            </p>

                            <h3>3 Rate:</h3>
                            <p>
                                The rates shall be firm throughout the term of the Contract and shall not be subject to any escalation due to increase 
                                in the price of materials, Labour Wages, or on any other account, etc., irrespective of all leads at all heights and all levels and 
                                any change in quantity, till the completion of work, even if the work is delayed for any reasons attributable to either of the Parties.
                            </p>

                            <h3>4 Mode of Measurement:</h3>
                            <p>
                                As mentioned in BOQ unit & specifications. Quantity measured shall be based on Actual work done at the site.
                            </p>
                        </div>
                        `
            }
          ]
        }));
      }, 2000);
    }
  }, [basic_contract_value]);



  // Initialize terms state with data from props
  useEffect(() => {
    setTerms(data?.terms?.[0]?.term_and_condition ?? []);
    setTablTerms(data?.terms?.[0]?.term_and_condition?.table_terms || [])
    setFileTerms(data?.terms?.[0]?.term_and_condition?.file_terms || [])

  }, [data]);


  useEffect(() => {
    if (mode === "normal") {
      fetchData(id);
    }

    fetchLetterheads()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (mode !== "normal" && inputId) {
      fetchData(inputId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputId]);

  if (loading) {
    return (
      <div style={styles.container}>
        <div style={styles.loadingContainer}>
          <Spin />
        </div>
      </div>
    );
  }

  console.log('vendor Purchaseasaaaa', data, terms)

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Vendor Quotation</title>
      </Helmet>

      {showsHeader && (
        <>
          <Breadcrumb style={styles.breadcrumbs}>
            {config?.breadcrumbs?.map((breadcrumb, index) => (
              <Breadcrumb.Item key={index}>
                <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>

          <PageHeader
            title={config?.title}
            style={styles.pageHeader}
            onBack={config?.actions?.onBack}
          ></PageHeader>
        </>
      )}

      <div style={styles.formContainer} ref={componentRef}>
        {letterheadFileName ? (
          <img
            src={`${STRAPI_URL}/uploads/${letterheadFileName}`}
            alt="Letterhead"
            style={{
              width: '100%',
              height: '150px',
              zIndex: -1, // Ensure it is behind other content
              objectFit: 'cover',
            }}
          />
        ) : (
          <div className="public-form-header">
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                width: "100%",
                border: "0px solid #ddd",
                marginBottom: "10px",
              }}
            >
              <tr style={{ borderBottom: "1px solid #eee" }}>
                <td
                  className="text-center"
                  style={{
                    background:
                      "url(/images/form-header-left.jpeg) no-repeat top left",
                    backgroundSize: "80%",
                    width: "30%",
                  }}
                >
                  <img
                    title="QuickSO"
                    src="/images/form-header-logo.png"
                    alt="QuickSO"
                    style={{
                      height: "75px",
                      width: "auto",
                      objectFit: "contain",
                      margin: "0 auto",
                    }}
                  />
                </td>

                <td
                  style={{
                    background:
                      "url(/images/form-header-right.jpeg) no-repeat top left",
                    backgroundSize: "100%",
                  }}
                >
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                      width: "100%",
                      border: "0px solid #ddd",
                      marginBottom: "0px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          colSpan={2}
                          style={{ borderBottom: "2px dashed #ddd" }}
                        >
                          <h1
                            className="text-left"
                            style={{
                              color: "#ffffff",
                              fontSize: "30px",
                              padding: "20px 20px",
                              textTransform: "uppercase",
                              margin: 0,
                            }}
                          >
                            VENDOR PURCHASE ORDER
                          </h1>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <h2
                            className="text-left"
                            style={{
                              color: "#ffffff",
                              fontSize: "16px",
                              padding: "10px 20px",
                              fontWeight: 300,
                            }}
                          >
                            Serial Number
                            <br />
                            <b>
                              VPO-
                              {
                                data?.vendor_material_requisition?.data?.attributes?.uid?.split(
                                  "-"
                                )?.[0]
                              }
                              -
                              {
                                data?.vendor?.data?.attributes?.uid?.split(
                                  "-"
                                )?.[0]
                              }
                            </b>
                          </h2>
                        </td>

                        <td>
                          <h2
                            className="text-left"
                            style={{
                              color: "#ffffff",
                              fontSize: "16px",
                              padding: "10px 20px",
                              fontWeight: 300,
                            }}
                          >
                            Date
                            <br />
                            <b>
                              {moment(data?.createdAt).format(
                                "DD-MM-YYYY, HH:mm"
                              )}
                            </b>
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        )}


        <div className="public-form-body">
          <div className="public-form-section">
            <h2 className="public-form-section-title">General Information</h2>

            <table className="public-form-general-info">
              <tr>
                <td>Vendor</td>
                <td>{data?.vendor?.data?.attributes?.name}</td>
              </tr>

              <tr>
                <td>Project</td>
                <td>{data?.project?.data?.attributes?.name}</td>
              </tr>

              <tr>
                <td>Delivery Address</td>
                <td>
                  {getDeliveryAddress(
                    data?.delivery_address?.data?.attributes
                  ) || "N/A"}
                </td>
              </tr>

              <tr>
                <td>Material Requisition</td>
                <td>{data?.heading}</td>
              </tr>

              <tr>
                <td>Urgency Level</td>
                <td>{data?.urgency_level}</td>
              </tr>

              <tr>
                <td>Expected Delivery Date</td>
                <td>
                  {data?.expected_delivery_date
                    ? moment(data?.expected_delivery_date)?.format("DD-MM-YYYY")
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td>Delivery Date</td>
                <td>
                  {data?.delivery_date
                    ? moment(data?.delivery_date)?.format("DD-MM-YYYY")
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td>Material Specifications</td>
                <td>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.vendor_material_requisition?.data?.attributes
                          ?.remarks,
                    }}
                  />
                </td>
              </tr>
            </table>
          </div>

          {data?.material_items?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Material Items</h2>

              <table className="public-form-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    {/* <th>Material Group</th>
                    <th>Material Item</th> */}
                    {/* <th>Material Unit</th> */}
                    <th style={{ width: "20%" }}>Item Description</th>
                    <th>Quantity</th>
                    <th style={{ width: "10%" }}>Labour Cost(₹)</th>
                    <th style={{ width: "10%" }}>Material Cost(₹)</th>
                    <th style={{ width: "10%" }}>Rate(₹)</th>
                    <th style={{ width: "10%" }}>CGST (%)</th>
                    <th style={{ width: "10%" }}>SGST (%)</th>
                    <th style={{ width: "10%" }}>IGST (%)</th>
                    <th style={{ width: "10%" }}>Taxable Amount(₹)</th>
                    <th style={{ width: "10%" }}>Total with Tax(₹)</th>
                  </tr>
                </thead>

                <tbody>
                  {data?.material_items?.length > 0 &&
                    data?.material_items?.map((materialItem, index) => {
                      const taxableAmount =
                        materialItem?.quantity * data?.material_item_notes?.[index]?.amount || 0;
                      const cqst = (data?.material_item_notes?.[index]?.cqst || 0) / 100;
                      const sqst = (data?.material_item_notes?.[index]?.sqst || 0) / 100;
                      const igst = (data?.material_item_notes?.[index]?.igst || 0) / 100;

                      // Calculate the total with taxes
                      const totalWithTax =
                        taxableAmount +
                        taxableAmount * cqst +
                        taxableAmount * sqst +
                        taxableAmount * igst;

                      return (
                        <tr key={materialItem?.id}>
                          <td className="text-center text-bold">{index + 1}</td>
                          {/* <td>{materialItem?.material_group || "N/A"}</td>
                          <td>{materialItem?.material_item || "N/A"}</td> */}
                          <td dangerouslySetInnerHTML={{ __html: materialItem?.item_description }}></td>
                          <td>{materialItem?.quantity || 0}</td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.material_item_notes?.[index]?.estimated_labour_cost || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.material_item_notes?.[index]?.estimated_material_cost || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.material_item_notes?.[index]?.amount || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.material_item_notes?.[index]?.cqst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.material_item_notes?.[index]?.sqst || 0}
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.material_item_notes?.[index]?.igst || 0}
                          </td>
                          <td>{Number(taxableAmount).toLocaleString()}</td>
                          <td>{Number(totalWithTax).toLocaleString()}</td>
                        </tr>
                      );
                    })}

                  <tr>
                    <td
                      colSpan={10}
                      style={{
                        background: "#fbfbfb",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Grand Total Amount (₹)
                    </td>

                    <td style={{ background: "#fbfbfb", fontWeight: "bold" }}>
                      {Number(data?.grand_total_amount).toLocaleString() || 0}
                    </td>
                  </tr>

                  {(!data?.material_items ||
                    data?.material_items?.length === 0) && (
                      <tr>
                        <td colSpan={8} style={{ padding: 24 }}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="N/A"
                value={materialItemsRemarks}
                style={{ marginBottom: 24 }}
                disabled={true}
              />
            </div>
          )}

          {terms?.terms?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Terms & Conditions</h2>
              {terms?.terms?.map((term, index) => (
                <div style={{ marginBottom: '20px', marginLeft: '20px' }}>
                  <strong key={index}>{term?.term_name}</strong>
                  <p dangerouslySetInnerHTML={{ __html: term?.term_details }}></p>
                </div>
              ))}
            </div>
          )}

          <div>
            <Table
              columns={columns}
              dataSource={tableTerms}
              rowKey="id"
              pagination={false}
              bordered
            />

            <div style={{ marginTop: '20px' }}>
              <Table
                columns={fileUploadColumns}
                dataSource={fileTerms}
                rowKey="id"
                pagination={false}
                bordered
              />
            </div>
          </div>

          <div className="public-form-section" style={{ margin: '26px 0' }}>
            <label style={styles.label}>Remarks</label>
            <TextArea
              placeholder="N/A"
              value={termsRemarks}
              disabled={true}
            />
          </div>

          {data?.payment_schedules?.length > 0 && (
            <div className="public-form-section">
              <h2 className="public-form-section-title">Payment Schedules</h2>
              <div style={{ overflowX: "auto" }}>
                <table className="public-form-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Material Group</th>
                      <th style={{ textAlign: "left", width: "30%" }}>
                        Description
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Amount (%)
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Amount (₹)
                      </th>
                      <th style={{ width: "20%", textAlign: "left" }}>
                        Date
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.payment_schedules?.map((paymentSchedule, index) => (
                      <tr key={paymentSchedule?.id}>
                        <td className="text-center text-bold">{index + 1}</td>
                        <td>{paymentSchedule?.name}</td>
                        <td>{paymentSchedule?.material_group?.data?.attributes?.name}</td>
                        <td style={{ textAlign: "left" }}>
                          {paymentSchedule?.description?.replace(/<\/?p>/g, "") || "N/A"}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {paymentSchedule?.amount_percentage}%
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {calculateAmount(paymentSchedule, data?.material_item_notes)}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {paymentSchedule?.date}
                        </td>
                      </tr>
                    ))}

                    {data?.paymentSchedules?.length === 0 && (
                      <tr>
                        <td colSpan={6} style={{ padding: 24 }}>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <label style={styles.label}>Remarks</label>
              <TextArea
                placeholder="Enter Remarks"
                value={paymentSchedulesRemarks}
                onChange={(e) => setPaymentSchedulesRemarks(e.target.value)}
                style={{ marginBottom: 24 }}
              />
            </div>
          )}

          {/* {showsCancel && (
            <div className="public-form-section" style={{ marginTop: 24 }}>
              <Button onClick={config?.actions?.onBack}>Cancel</Button>
            </div>
          )} */}

          <div className="public-form-section">
            <table style={{ width: "100%" }}>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <div
                      style={{
                        background:
                          "url(/images/form-line.png) no-repeat center center",
                        backgroundAttachment: "cover",
                        backgroundSize: "100%",
                        height: 42,
                        marginTop: 24,
                        marginBottom: 10,
                      }}
                    />
                  </td>
                </tr>

                <tr className="text-center" style={{ fontSize: "14px" }}>
                  <td className="text-left" style={{ padding: "5px 0px" }}>
                    <img
                      title="QuickSO"
                      src="/images/form-logo.png"
                      alt="QuickSO"
                      style={{
                        height: "50px",
                        width: "auto",
                      }}
                    />
                  </td>
                  <td
                    className="text-right"
                    style={{ padding: "5px 0px", fontSize: "16px" }}
                  >
                    Copyright © 2022, All Rights Reserved by QuickSO
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <Button type="primary" onClick={() => setShowsModal(true)} style={{ marginTop: 24 }}>
        Download as PDF
      </Button>

      <Modal
        title="Print PDF"
        visible={showsModal}
        onOk={onSubmit}
        onCancel={() => setShowsModal(false)}
        destroyOnClose
      >
        <label style={{ ...styles.label, marginTop: 0 }}>Letterhead</label>
        <Select
          value={letterheadFileName}
          onChange={setLetterheadFileName}
          style={styles.select}
        >
          {letterheads?.map((letterhead, index) => (
            <Option key={index} value={letterhead?.file_name}>
              {letterhead?.name}
            </Option>
          ))}
        </Select>

        <label style={styles.label}>Preview</label>
        <img
          src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${letterheadFileName}`}
          style={styles.previewedImage}
          alt="Preview"
        />
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    background: "white",
  },
  datePicker: {
    width: "100%",
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  loadingContainer: {
    textAlign: "center",
    padding: 24,
  },
  label: {
    fontWeight: "bold",
    display: "block",
    marginBottom: 6,
  },
  formContainer: {
    border: "2px solid #eee",
  },
  select: {
    width: "100%",
  },
};

export default Page;
