import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Employee",
    //   name: "employee",
    //   placeholder: "Select employee",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Employee is required!",
    //     },
    //   ],
    // },
    {
      span: 12,
      component: "input",
      label: "Recieved Invoice Number",
      name: "no",
      placeholder: "Invoice Number",
    },
    // {
    //   span: 12,
    //   component: "date",
    //   label: "Invoice date",
    //   name: "invoice_date",
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Invoice Type",
    //   name: "invoice_type",
    //   placeholder: "Select Type",

    // },

    {
      span: 12,
      component: "select",
      label: "User",
      name: "user_type",
      placeholder: "Select User",

    },
    {
      span: 24,
      component: "select",
      label: "vendor",
      name: "vendor",
      placeholder: "Select vendor",

    },
    {
      span: 24,
      component: "select",
      label: "Sub Contractor",
      name: "sub_contractor",
      placeholder: "Select subcontractor",
    },
    {
      span: 24,
      component: "select",
      label: "Agency",
      name: "agency",
      placeholder: "Select agency",
    },

    {
      span: 12,
      component: "select",
      label: "Projects",
      name: "project",
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Projects is required!",
        },
      ],
    },


    {
      span: 12,
      component: "select",
      label: "Payment Schedule",
      name: "payment",
      placeholder: "Select payment",
      required: true,
      rules: [
        {
          required: true,
          message: "Payment Schedule is required!",
        },
      ],
    },

    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Approved By Accounts",
    //   name: "approved_by_accounts",
    //   placeholder: "Select employee",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Approved by is required!",
    //     },
    //   ],
    // },
    // {
    //   span: 12,
    //   component: "select",
    //   label: "Status",
    //   name: "status",
    //   placeholder: "Select status",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Status is required!",
    //     },
    //   ],
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Remarks",
    //   name: "remarks",
    //   placeholder: "Enter remarks",
    //   rows: 5,
    // },
    // {
    //   span: 24,
    //   component: "editor",
    //   label: "Description",
    //   name: "description",
    //   placeholder: "Enter description",
    //   rows: 5,
    // },
    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {

      console.log('valuessss', values, data);
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
            amount_payable: Number(values?.payable_amount),
            uid: v4(),
            reconciliation: data?.reconciliation,
            payment_schedule: data?.payment_schedule,
            status: null
            // creator: auth?.user?.id,
          },
        });

        if (response?.data) {
          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
