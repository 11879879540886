import React, { useEffect, useState } from 'react'
import config from '../BudgetTracker/config';
import { MAX_PAGINATION_LIMIT } from '../../../../constants';
import qs from "qs";
import _ from "lodash";
import { useNavigate } from 'react-router-dom';
import { axios } from '../../../../App';
import { Button, Col } from 'antd';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import { TbReportAnalytics } from "react-icons/tb";

const BASE_MODULE_URL = "/budget-tracker";

const BudgetReports = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const fetchData = async (params = null) => {
        try {

            const queryObject = {
                populate: "*",
                pagination: {
                    limit: MAX_PAGINATION_LIMIT,
                },
            };

            if (!_.isEmpty(params)) {
                queryObject["filters"] = params;
            }

            const response = await axios.get(
                `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
            );


            if (response?.data) {
                const filter_by_phase = response?.data?.data?.filter((data) => data?.attributes?.phase?.data?.length > 0);


                setData(
                    filter_by_phase?.map((item) => {
                        const phases = item?.attributes?.phase?.data || [];

                        const currentPhaseIndex = phases.length - 1;
                        const previousPhaseIndex = phases.length - 2;

                        const currentPhase = phases?.[0]?.attributes;
                        const previousPhase = phases[previousPhaseIndex]?.attributes;

                        const current_total_cost = currentPhase
                            ? currentPhase.total_actual_budget + currentPhase.total_estimated_budget
                            : null;

                        const previous_total_cost = previousPhase
                            ? previousPhase.total_actual_budget + previousPhase.total_estimated_budget
                            : null;

                        const actual_cost = currentPhase ? currentPhase?.total_actual_budget : 0;
                        const estimated_cost = currentPhase ? currentPhase?.total_estimated_budget : 0

                        return {
                            id: item?.id,
                            currentPhase: phases.length > 0 ? `R${currentPhaseIndex}` : '',
                            prev_cost: previous_total_cost ?? 0,
                            curr_cost: current_total_cost ?? 0,
                            actual_cost,
                            estimated_cost,
                            ...item?.attributes,
                        };
                    })
                );


            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const REPORTS = [
        {
            title: "Activity Budget",
            url: `${BASE_MODULE_URL}/report/activity-budget`,
        },
        {
            title: "Vendors Ledger",
            url: `${BASE_MODULE_URL}/report/vendors-ledger`,
        },
        {
            title: "Vendors Master Data",
            url: `${BASE_MODULE_URL}/report/vendors-masterdata`,
        },
        {
            title: "Balance to Pay",
            url: `${BASE_MODULE_URL}/report/balance-to-pay`,
        },
        {
            title: "Payment Tracker",
            url: `${BASE_MODULE_URL}/report/payment-tracker`,
        },
        // {
        //     title: "Mobilization Advance",
        //     url: `${BASE_MODULE_URL}/report/mobilization-advance`,
        // },
        {
            title: "Cummulative Work Reports",
            url: `${BASE_MODULE_URL}/report/cummulative`,
        }
    ];


    console.log('--------------------------------', data);

    return (
        <div>
            <Col span={24}>
                <Stack direction={{ base: "column", md: "row", lg: "row" }}>
                    <Box w={{ base: "auto", md: "100%" }}>
                        <Col>
                            <div style={styles.container}>
                                <div style={styles.header}>
                                    <p style={styles.headerTitle}>Reports</p>
                                </div>

                                <div style={styles.body}>
                                    <SimpleGrid columns={[1, 2, 3, 4]}>
                                        {REPORTS?.map((report) => (
                                            <Card
                                                title={report?.title}
                                                url={report?.url}
                                            />
                                        ))}

                                        {/* <Card
                                                title={report?.project?.data?.attributes?.name}
                                                url={`${BASE_MODULE_URL}/report/${report?.id}?project=${report?.project?.data?.id}&name=${report?.project?.data?.attributes?.name}`}
                                                title={report?.title}
                                                url={report?.url}
                                            /> */}

                                        {/* <Card title="View All Reports" url={`${BASE_MODULE_URL}/reports`} /> */}
                                    </SimpleGrid>
                                </div>
                            </div>
                        </Col>
                    </Box>
                </Stack>
            </Col>
        </div>
    )
}

const Card = ({ url, title }) => {
    return (
        <Col span={6}>
            <div style={styles.card}>
                <TbReportAnalytics style={styles.cardIcon} />
                <Button type="link" href={url} target="_blank">
                    {title}
                </Button>
            </div>
        </Col>
    );
};

const styles = {
    container: {
        boxShadow: "0 0 20px 0 rgb(29 31 89 / 10%)",
        borderRadius: 10,
        overflow: "hidden",
        marginBottom: 24,
    },
    header: {
        padding: "10px 15px",
        borderBottom: "1px solid #cf6c58",
        background: "#cf6c58",
    },
    headerTitle: {
        fontSize: "1.1rem",
        margin: "0rem",
        fontWeight: "500",
        color: "white",
    },
    body: {
        padding: 16,
        background: "white",
    },
    card: {
        display: "flex",
        alignItems: "center",
    },
    cardIcon: {
        fontSize: 24,
    },
};

export default BudgetReports