import { axios } from "../../../../../App";
import config from "../config";

export const makeConfig = ({ navigate }) => ({
  id: `${config?.plural}-edit`,
  title: `Edit ${config?.entityNames?.singular}`,
  pageTitle: `Edit ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Edit ${config?.entityNames?.singular}`,
    },
  ],
  components: [

    {
      span: 12,
      component: "input",
      label: "Recieved Invoice Number",
      name: "no",
      disabled: true,
      placeholder: "Invoice Number",
    },
    // Invoice Date

    {
      span: 12,
      component: "date",
      label: "Invoice date",
      name: "invoice_date",
      disabled: true,
    },
    {
      span: 12,
      component: "select",
      label: "Invoice Type",
      name: "invoice_type",
      disabled: true,
      placeholder: "Select Type",

    },

    {
      span: 12,
      component: "select",
      label: "User",
      name: "user_type",
      disabled: true,
      placeholder: "Select User",

    },
    {
      span: 24,
      component: "select",
      label: "vendor",
      name: "vendor",
      disabled: true,
      placeholder: "Select vendor",

    },
    {
      span: 24,
      component: "select",
      label: "Sub Contractor",
      name: "sub_contractor",
      disabled: true,
      placeholder: "Select subcontractor",
    },
    {
      span: 24,
      component: "select",
      label: "Agency",
      name: "agency",
      disabled: true,
      placeholder: "Select agency",
    },

    {
      span: 12,
      component: "select",
      label: "Projects",
      name: "project",
      disabled: true,
      placeholder: "Select project",
      required: true,
      rules: [
        {
          required: true,
          message: "Projects is required!",
        },
      ],
    },


    {
      span: 12,
      component: "select",
      label: "Payment Schedule",
      name: "payment",
      disabled: true,
      placeholder: "Select payment",

    },

    {
      span: 12,
      component: "number",
      label: "Amount Payable",
      name: "payable_amount",
      disabled: true,
      placeholder: "Payable Amount",
      disabled: true,

    },

    {
      span: 12,
      component: "uploader",
      label: "Attachment",
      name: "attachment",
      disabled: true,
      required: true,
      placeholder: "Upload attachment",
      rules: [
        {
          required: true,
          message: "Attachment is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By",
      name: "approved_by",
      disabled: true,
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Approved By Accounts",
      name: "approved_by_accounts",
      placeholder: "Select employee",
      required: true,
      rules: [
        {
          required: true,
          message: "Approved by is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Status",
      name: "status",
      placeholder: "Select status",
      required: true,
      rules: [
        {
          required: true,
          message: "Status is required!",
        },
      ],
    },
    {
      span: 24,
      component: "editor",
      label: "Remarks",
      name: "remarks",
      placeholder: "Enter remarks",
      rows: 5,
    },

    {
      span: 24,
      component: "input",
      type: "hidden",
      name: "attachment",
    },
  ],
  actions: {
    onBack: () => navigate(`/quick-stocks/${config?.slugs?.plural}`),
    onFinish: async (values, data) => {
      try {
        const response = await axios.put(
          `/${config?.slugs?.plural}/${data?.id}`,
          {
            data: {
              ...values,
              reconciliation: data?.reconciliation,
              payment_schedule: data?.payment_schedule
            },
          }
        );

        if (response?.data) {
          return {
            success: true,
            message: `Edit ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: error?.response?.data?.error?.message,
        };
      }
    },
  },
  ...config,
});
