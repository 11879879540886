import React, { useState, useEffect } from "react";
import { AddForm } from "../../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";


const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const config = makeConfig({ navigate, auth });
  const [values, setValues] = useState({
    status: ["Pending", "Active", "On Hold", "Completed"]?.map((item) => ({
      value: item,
      label: item,
    })),
    type: ["Task", "Milestone"]?.map((item) => ({
      value: item,
      label: item,
    })),
    all_tasks: [],
    all_milestones: [],
  });
  const contentTypes = [
    {
      slug: "employees",
      query: {
        sort: ["first_name:asc"],
        filters: {
          is_deleted: false,
        },
      },
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          assignees: data,
        })),
    },
    {
      slug: "projects",
      query: {
        populate: "*",
        ...(projectId && {
          filters: {
            id: projectId
          }
        }),
      },
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
        location: item?.location
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          project: data,
          // parent: data,
        })),
    },
    {
      slug: "machines",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          machines: data,
        })),
    },
    {
      slug: "hand-tools",
      transformer: (item) => ({
        value: item?.id,
        label: item?.name,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          hand_tools: data,
        })),
    },
    {
      slug: "tasks",
      query: {
        populate: "*",
      },
      onSuccess: (data) => {
        setValues((prev) => ({
          ...prev,
          dependencies: data?.map((item) => ({
            value: item?.id,
            label: item?.name,
            type: item?.type,
          })),
          all_tasks: data,
          all_milestones: data
            ?.filter((item) => item?.type === "Milestone")
            ?.map((item) => ({
              value: item?.id,
              label: item?.name,
              type: item?.type,
              project_id: item?.project?.data?.id,
            })),
        }));
      },
    },
    {
      slug: "work-items",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          all_work_item: data,
        })),
    },
    {
      slug: "holidays",
      query: {
        populate: "*",
      },
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          holidays: data,
        })),
    },
  ];

  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('task consoless', values)

  return (
    <AddForm
      config={config}
      values={values}
      mode={props?.mode}
      onEmbedFinish={props?.onEmbedFinish}
      onEmbedCancel={props?.onEmbedCancel}
      data={props?.data}
      disabledItems={props?.disabledItems}
      visibility={{
        milestone: (data) => {
          return data?.type === "Task";
        },
        sub_milestones: (data) => {
          return data?.type === "Task";
        },
      }}
      onFieldChange={{
        type: (antForm) => {
          const typeId = antForm.getFieldValue("type");
          const projectId = antForm.getFieldValue("project");

          antForm.setFieldValue("milestone", undefined);

          if (typeId && typeId?.toLowerCase() === "task") {
            setValues((prev) => ({
              ...prev,
              milestone: values?.all_milestones?.filter(
                (item) => item?.project_id === Number(projectId)
              ),
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              milestone: [],
              sub_milestones: []
            }));
          }
        },
        project: async (antForm) => {
          const typeId = antForm.getFieldValue("type");
          const projectId = antForm.getFieldValue("project");

          antForm.setFieldValue("milestone", undefined);

          console.log('projectId', typeId, projectId);

          if (typeId?.toLowerCase() === "task") {
            setValues((prev) => ({
              ...prev,
              milestone: values?.all_milestones?.filter(
                (item) => item?.project_id === Number(projectId)
              ),
            }));
          } else {
            setValues((prev) => ({
              ...prev,
              milestone: [],
              sub_milestones: []
            }));
          }

          const queryObject = {
            populate: "*",
            filters: {
              project: projectId,
            }
          };

          // Call Budget Tracker when the project is selected
          const response = await axios.get(
            `budget-trackers?${qs.stringify(queryObject)}`
          );

          const budget_tracker_id = response?.data?.data?.[0]?.id

          const phaseObject = {
            populate: "*",
            filters: {
              budget_tracker: budget_tracker_id,
            }
          };

          const res = await axios.get(
            `budget-tracker-phases?${qs.stringify(phaseObject)}`
          );

          if (res?.data?.data?.length > 0) {

            const milestone_map = {};

            res?.data?.data?.forEach((item) => {
              item?.attributes?.milestone?.data?.forEach((milestone) => {

                milestone_map[milestone.id] = {
                  // Use underscore-style naming for the keys in your new object, or rename as you prefer
                  value: milestone.id,
                  label: milestone.attributes.name,
                  budget_id: item.id
                };
              });
            });

            const sub_milestones = Object.values(milestone_map);

            setValues((prev) => ({
              ...prev,
              milestone: sub_milestones,
            }));
          }

        },

        milestone: async (antForm) => {
          const selected_sub_milestones = antForm.getFieldValue("milestone");
          const phase_id = values?.milestone?.find((value) => value.value === selected_sub_milestones)?.budget_id

          const phaseObject = {
            populate: "*",
            filters: {
              id: phase_id,
            }
          };

          // Call Budget Tracker when the project is selected
          const response = await axios.get(
            `budget-tracker-phases?${qs.stringify(phaseObject)}`
          );

          const sub_sub_milestones = response?.data?.data?.[0]?.attributes?.sub_milestone[selected_sub_milestones]

          const transformed_sub_milestones = sub_sub_milestones.map(milestone => ({
            ...milestone,
            value: milestone?.id,
            label: milestone?.sub_milestone,
          }))

          setValues((prev) => ({
            ...prev,
            sub_milestones: transformed_sub_milestones,
          }));

        },

        sub_milestones: async (antForm) => {
          const selected_sub_sub_milestones = antForm.getFieldValue("sub_milestones");
          const sub_sub_milestone = values?.sub_milestones?.find((value) => value.value === selected_sub_sub_milestones)

          antForm.setFieldValue("estimated_budget", sub_sub_milestone?.estimated_budget ?? 0);
          antForm.setFieldValue("actual_budget", sub_sub_milestone?.actual_budget ?? 0);

          antForm.setFieldValue("selected_sub_milestone", sub_sub_milestone);

        }
      }}
    />
  );
};

export default Page;
