import config from "../../BudgetTracker/config";

export default {
  id: `balance-list`,
  pageTitle: 'Balance To Pay',
  breadcrumbs: [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "",
      title: 'Balance To Pay',
    },
  ],
  columns: [
    {
      title: "Project Name",
      dataIndex: "project",
      selectable: true,
      references: {
        fieldName: "name",
      },
    },
    {
      title: "Current Phase",
      dataIndex: "currentPhase",
      selectable: true,
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimated_cost",
      selectable: true,
    },
    {
      title: "Actual Cost",
      dataIndex: "actual_cost",
      selectable: true,
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   selectable: true,
    //   sorter: (a, b) => a?.description?.localeCompare(b?.description),
    //   render: (text) =>
    //     text !== "" ? (
    //       <span
    //         className="html-cell"
    //         dangerouslySetInnerHTML={{ __html: text }}
    //       ></span>
    //     ) : (
    //       "N/A"
    //     ),
    // },
  ],
  ...config,
};
