import config from "../config";

const commify = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
  id: `${config?.slugs?.plural}-list`,
  pageTitle: 'Payment Tracker',
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: "",
      title: 'Payment Tracker',
    },
  ],
  columns: [
    {
      title: "Project",
      dataIndex: "project",
      selectable: true,
      filterable: true,
      references: {
        fieldName: (record) => ` ${record?.name}`,
      },
    },
    {
      title: "Approved By",
      dataIndex: "approved_by_account",
      selectable: true,
      filterable: true,

    },
    {
      title: "Status",
      dataIndex: "status",
      selectable: true,
    },
    {
      title: "Amount Payable",
      dataIndex: "amount_payable",
      selectable: true,
      render: (text) => commify(text || 0),
      sorter: (a, b) => a?.amount_payable - b?.amount_payable,
    },
  ],
  ...config,
};
