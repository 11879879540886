import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { makeConfig } from "./config";
import VendorQuotationView from "../../VendorQuotations/View";
import { Helmet } from "react-helmet";
import {
  Empty,
  PageHeader,
  Breadcrumb,
  Space,
  Button,
  Modal,
  notification,
  Spin,
  Input,
  Form,
  Select,
} from "antd";
import { axios } from "../../../../../App";
import qs from "qs";
import moment from "moment";
import Previewer from "./Previewer";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const Page = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);

  const config = makeConfig({ navigate });
  const { id } = useParams();
  const [vendors, setVendors] = useState([]);
  const [data, setData] = useState([]);
  const [activeVendorIndex, setActiveVendorIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [activeQuotation, setActiveQuotation] = useState({});
  const [showApprove, setShowApprove] = useState(false);
  const [approvalRemarks, setApprovalRemarks] = useState("");
  const [approvedBy, setApprovedBy] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [hasApprovedQuotation, setHasApprovedQuotation] = useState(false);
  const [budget_phases, setBudget_phases] = useState(null)

  const [otpSent, setOtpSent] = useState(false); // To track if OTP has been sent
  const [otpVerified, setOtpVerified] = useState(false); // To track if OTP is verified
  const [otp, setOtp] = useState(""); // To store the entered OTP
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState(""); // To show errors

  const fetchEmployees = async () => {
    try {
      const queryObject = {
        populate: "*",
        filters: {
          is_deleted: false,
        },
      };

      const response = await axios.get(
        `/employees?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setEmployees(response?.data?.data);

        if (response?.data?.data?.length > 0) {
          setApprovedBy(response?.data?.data?.[0]?.id);
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchQuotations = async () => {
    try {
      setLoading(true);
      setVendors([]);
      setActiveVendorIndex(0);

      const queryObject = {
        populate: "*",
        filters: {
          vendor_material_requisition: id,
          // status: {
          //   $ne: "Approved",
          // },
        },
      };

      const response = await axios.get(
        `/vendor-quotations?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(response?.data?.data);

        const approvedQuotations = response?.data?.data?.filter(
          (item) => item?.attributes?.status === "Approved"
        );

        setActiveQuotation({
          id: response?.data?.data?.[0]?.id,
          ...response?.data?.data?.[0]?.attributes,
        });
        setHasApprovedQuotation(approvedQuotations?.length > 0);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDetails = () => setShowDetails((prev) => !prev);

  const toggleApprove = () => setShowApprove((prev) => !prev);

  const onViewDetails = (item) => () => {
    setActiveQuotation(item);
    toggleDetails();
  };

  const onSubmitApproval = async (values) => {
    let updated_budget_phases = updateLastMatchingSubmilestone(budget_phases, data?.[0]?.attributes?.task?.data?.attributes?.sub_milestone);

    try {
      const response = await axios.put(
        `/vendor-quotations/${activeQuotation?.id}`,
        {
          data: {
            approved_by: values?.approved_by,
            approval_remarks: values?.approval_remarks,
            status: "Approved",
          },
        }
      );
      if (response?.data) {
        const final_data = {
          sub_milestone: updated_budget_phases?.attributes?.sub_milestone,
          allocated_budget: updated_budget_phases?.attributes?.allocated_budget + data?.[0]?.attributes?.grand_total_amount
        }

        await axios.put(`/budget-tracker-phases/${updated_budget_phases?.id}`, {
          data:
            final_data
        });

        resetApprove();
        fetchQuotations();

        notification["success"]({
          message: "Approve Quotation",
          description: "The quotation has been approved!",
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: "Approve Quotation",
        description: "Failed to approve the quotation!",
      });
    }
  };

  //////////////////////////////////////////////////////////////

  const resetApprove = () => {
    setShowApprove(false);
    setApprovalRemarks("");
    setApprovedBy(employees?.[0]?.id);
  };

  const onSelectQuotation = (e, record) => {
    e.stopPropagation();

    setActiveQuotation({
      id: record?.id,
      ...record?.attributes,
    });
  };

  // Mock function to simulate API call to send OTP
  const sendOtp = async () => {
    setErrorMessage("");
    try {
      // Replace with your actual API call
      const response = await axios.post('/otp/sendOtp', {
        email: email,
        client_uid: auth?.user?.client_uid
      });

      console.log('otp', response)

      if (response.status === 200) {
        notification["success"]({
          message: "OTP",
          description: "OTP Sent!",
        });
        setOtpSent(true);
      } else {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong.");
    }
  };

  // Mock function to verify OTP
  const verifyOtp = async () => {
    setErrorMessage("");
    try {
      // Replace with your actual OTP verification API call
      const response = await axios.post('/otp/verifyotp', {
        otp: otp,
        email: auth?.user?.email_1,
        client_uid: auth?.user?.client_uid
      });

      if (response?.data?.accessGranted) {
        notification["success"]({
          message: "OTP",
          description: "OTP Verified!",
        });
        setOtpVerified(true);
      } else {
        setErrorMessage("Incorrect OTP. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Something went wrong.");
    }
  };

  const getDeliveryAddress = (addressObject) => {
    return `${addressObject?.name} (${addressObject?.address_line_1}, ${addressObject?.address_line_2})`;
  };

  useEffect(() => {
    fetchEmployees();
    fetchQuotations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    const fetchPhases = async () => {
      const queryObject = {
        populate: "*",
        filters: {
          project: data?.[0]?.attributes?.project?.data?.id
        }
      };

      // Call Budget Tracker when the project is selected
      const response = await axios.get(
        `budget-trackers?${qs.stringify(queryObject)}`
      );

      const budget_tracker_id = response?.data?.data?.[0]?.id

      const phaseObject = {
        populate: "*",
        filters: {
          budget_tracker: budget_tracker_id,
        }
      };

      const res = await axios.get(
        `budget-tracker-phases?${qs.stringify(phaseObject)}`
      );

      if (res?.data?.data) {
        setBudget_phases(res?.data?.data?.[res?.data?.data?.length - 1])
      }

    }

    if (data?.length > 0) {
      fetchPhases()
    };

  }, [data])


  useEffect(() => {
    if (auth?.user?.email_1) {
      setEmail(auth?.user?.email_1)
    }
  }, [auth?.user?.email_1])

  function updateLastMatchingSubmilestone(budget_phases, sub_milestone) {
    // ---------------------------------------------------------
    // budget_phases: full object that has budget_phases.attributes.sub_milestone
    // sub_milestone: object that indicates what we want to update, e.g.:
    //    {
    //      id: "dcc236ce-987d-4f36-838e-880d53a1eefa",
    //      milestone: 565,
    //      ...
    //    }
    // We'll assume sub_milestone.milestone is the key used in budget_phases.
    // ---------------------------------------------------------

    // Step 1: Extract milestone ID from the incoming sub_milestone object
    const milestone_id = sub_milestone?.milestone;

    // Defensive check: ensure we actually have a milestone ID
    if (!milestone_id) {
      console.log('No milestone ID found. Cannot update sub-milestone.');
      return budget_phases;
    }

    // Step 2: Get the relevant array from budget_phases, which should be
    // under budget_phases.attributes.sub_milestone[milestone_id].
    // If that doesn't exist or isn't an array, return early.
    const sub_milestones_arr = budget_phases?.attributes?.sub_milestone?.[milestone_id];
    if (!Array.isArray(sub_milestones_arr)) {
      console.log('sub_milestones array not found. Nothing to update.');
      return budget_phases;
    }

    // Step 3: Loop from the last item backward to find the last sub-milestone
    // that has the same id as sub_milestone.id:
    const target_id = sub_milestone.id;
    let found_index = -1;  // store the index of the last matching sub-milestone
    for (let i = sub_milestones_arr.length - 1; i >= 0; i--) {
      if (sub_milestones_arr[i].id === target_id) {
        found_index = i;
        break;  // we found the last (rightmost) match, so we can stop searching
      }
    }

    // If we didn't find it, do nothing
    if (found_index === -1) {
      console.log('No matching subMilestone ID found in sub_milestones array. Nothing updated.');
      return budget_phases;
    }

    let totalLabourCost = 0;
    let totalMaterialCost = 0;
    let totalBudget = 0;
    let taxableAmount = 0;

    data?.[0]?.attributes?.material_item_notes?.forEach(item => {
      // Multiply costs by quantity
      const labourCost = item.estimated_labour_cost * item.quantity;
      const materialCost = item.estimated_material_cost * item.quantity;

      // Calculate total before tax
      let totalCost = labourCost + materialCost;

      // Apply taxes (CQST and SQST are percentages)
      const cqstTax = isNaN((totalCost * item.cqst) / 100) ? 0 : (totalCost * item.cqst) / 100;
      const sqstTax = isNaN((totalCost * item.sqst) / 100) ? 0 : (totalCost * item.sqst) / 100;
      const igstTax = isNaN((totalCost * item.igst) / 100) ? 0 : (totalCost * item.igst) / 100;

      const total_tax = cqstTax + sqstTax + igstTax

      // Add taxes to total cost
      totalCost += total_tax;

      // Accumulate values
      totalLabourCost += labourCost;
      totalMaterialCost += materialCost;
      totalBudget += totalCost;
      taxableAmount += labourCost + materialCost;
    });


    sub_milestones_arr[found_index].vendor = data?.[0]?.attributes?.vendor?.data

    // Step 4: Update the object at found_index. For demonstration,
    // let's update the actual_budget. You can of course update any
    // fields you require: e.g. sub_milestones_arr[found_index].actual_labour_cost, etc.
    sub_milestones_arr[found_index].actual_labour_cost = totalLabourCost;
    sub_milestones_arr[found_index].actual_material_cost = totalMaterialCost;
    sub_milestones_arr[found_index].actual_budget = totalBudget;
    sub_milestones_arr[found_index].taxable_amount = taxableAmount;
    // or maybe we also do sub_milestones_arr[found_index].actual_labour_cost = '9999'; etc.

    // Return the updated budget_phases object.
    return budget_phases;
  }



  console.log('Vendor Approve', data)

  return (
    <div style={styles.pageContainer}>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <button onClick={onSubmitApproval}>gfighfi</button>

      <PageHeader
        title={
          <span style={styles.pageTitle}>
            {config?.title}
          </span>
        }
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      {loading && (
        <div style={styles.loaderContainer}>
          <Spin />
        </div>
      )}

      {!loading && data?.length === 0 && <Empty />}

      {!loading && data?.length > 0 && (
        <>
          <Previewer data={activeQuotation} />

          <div>
            {/* If OTP is not verified, show Send OTP workflow */}
            {!otpVerified ? (
              <div style={{ marginBottom: "20px" }}>
                {!otpSent ? (
                  <>
                    <Input
                      placeholder="Enter your email"
                      value={email}
                      style={{ marginBottom: "10px" }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button type="primary" onClick={sendOtp}>
                      Send OTP
                    </Button>
                  </>
                ) : (
                  <>
                    <Input
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      style={{ marginBottom: "10px" }}
                    />
                    <Button type="primary" onClick={verifyOtp}>
                      Verify OTP
                    </Button>
                  </>
                )}
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </div>
            ) : (

              <div style={styles.container}>
                {data?.map((record, index) => (
                  <div
                    key={index}
                    style={{
                      ...styles.vendor,
                      border:
                        record?.id === activeQuotation?.id
                          ? "2px solid #017bfe"
                          : "2px solid #eee",
                    }}
                    onClick={(e) => onSelectQuotation(e, record)}
                  >
                    <h3 style={styles.vendorName}>
                      {record?.attributes?.vendor?.data?.attributes?.name}
                    </h3>
                    <p>
                      - <strong>Grand Total Amount(₹):</strong>{" "}
                      {record?.attributes?.grand_total_amount || 0}
                    </p>
                    <p>
                      - <strong>Delivery Date:</strong>{" "}
                      {record?.attributes?.delivery_date
                        ? moment(record?.attributes?.delivery_date)?.format(
                          "DD-MM-YYYY"
                        )
                        : "N/A"}
                    </p>
                    <p>
                      - <strong>Delivery Address:</strong>{" "}
                      {getDeliveryAddress(
                        record?.attributes?.delivery_address?.data?.attributes ??
                        "N/A"
                      )}
                    </p>

                    <Space style={styles.buttons}>
                      <Button
                        block
                        onClick={onViewDetails({
                          id: record?.id,
                          ...record?.attributes,
                        })}
                      >
                        View Details
                      </Button>

                      {!hasApprovedQuotation &&
                        record?.attributes?.status !== "Approved" && (
                          <Button type="primary" block onClick={toggleApprove}>
                            Approve
                          </Button>
                        )}
                    </Space>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      <Modal
        title="Approve Quotation"
        visible={showApprove}
        onCancel={toggleApprove}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          h="max-content"
          onFinish={onSubmitApproval}
          autoComplete="off"
          layout="vertical"
          initialValues={{ approved_by: approvedBy }}
        >
          <Form.Item
            label="Remarks"
            name="approval_remarks"
            rules={[{ required: true, message: "Remarks is required!" }]}
          >
            <TextArea
              placeholder="Enter Remarks"
              value={approvalRemarks}
              onChange={(e) => setApprovalRemarks(e.target.value)}
              rows={3}
            />
          </Form.Item>

          <Form.Item
            label="Approved By"
            name="approved_by"
            rules={[{ required: true, message: "Approved by is required!" }]}
          >
            <Select value={approvedBy} onChange={setApprovedBy}>
              {employees?.map((employee) => (
                <Option value={employee?.id} key={employee.id}>
                  {employee?.attributes?.first_name}{" "}
                  {employee?.attributes?.last_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item style={{ margin: 0 }}>
            <Space style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button htmlType="button" block onClick={toggleApprove}>
                Cancel
              </Button>

              <Button type="primary" htmlType="submit" block>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      {activeQuotation && (
        <Modal
          title="View Quotation Details"
          visible={showDetails}
          onCancel={toggleDetails}
          width={1200}
          footer={[<Button onClick={toggleDetails}>Cancel</Button>]}
          destroyOnClose={true}
          bodyStyle={{ padding: 0 }}
        >
          <VendorQuotationView
            showsHeader={false}
            showsCancel={false}
            inputId={activeQuotation?.id}
            mode="embed"
            {...props}
          />
        </Modal>
      )}
    </div>
  );
};

const styles = {
  pageContainer: {},
  pageTitle: {
    whiteSpace: "normal",
    overflow: "hidden",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridColumnGap: 24,
    border: "1px solid #ccc",
    padding: 24,
    maxHeight: 240,
    overflowY: "auto",
    background: "#eee",
  },
  vendor: {
    padding: "12px 16px",
    border: "1px solid #eee",
    cursor: "pointer",
    background: "white",
  },
  details: {
    margin: 0,
    padding: 0,
  },
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  buttons: {
    marginTop: 12,
    width: "100%",
  },
  loaderContainer: {
    textAlign: "center",
    padding: 24,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 12,
  },
  detailsContainer: {
    border: "1px solid #eee",
  },
  vendorName: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 6,
  },
};

export default Page;
