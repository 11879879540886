import { axios } from "../../../../../App";
import { v4 } from "uuid";
import config from "../config";

export const makeConfig = ({ navigate, auth }) => ({
  id: `${config?.slugs?.plural}-add`,
  title: `Add New ${config?.entityNames?.singular}`,
  pageTitle: `Add New ${config?.entityNames?.singular}`,
  breadcrumbs: [
    {
      path: "/quick-stocks/dashboard",
      title: "Home",
    },
    {
      path: `/quick-stocks/${config?.slugs?.plural}`,
      title: config?.entityNames?.plural,
    },
    {
      path: "",
      title: `Add New ${config?.entityNames?.singular}`,
    },
  ],
  components: [
    {
      span: 12,
      component: "select",
      label: "Procurement",
      name: "procurement",
      placeholder: "Select procurement",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },
    {
      span: 12,
      component: "select",
      label: "Purchase order",
      name: "vendor_quotation",
      placeholder: "Vendor Purchase Orders",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },

    {
      span: 12,
      component: "select",
      label: "Work order",
      name: "sub_contractor_wo",
      placeholder: "Subcontractor Work Orders",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },

    {
      span: 12,
      component: "select",
      label: "Work order",
      name: "agency_wo",
      placeholder: "Agency Work Orders",
      required: true,
      rules: [
        {
          required: true,
          message: "Employee is required!",
        },
      ],
    },

    {
      span: 12,
      component: "select",
      label: "Recieved Invoices",
      name: "received_invoice",
      placeholder: "Recieved Invoices",
      required: true,
      rules: [
        {
          required: true,
          message: "Recieved Invoices is required",
        },
      ],
    },

    {
      span: 12,
      component: "input",
      label: "Work Order Value (Excl Taxes)",
      name: "order_value",
      placeholder: "Work Order Value (Excl Taxes)",
      disabled: true,
    },

    {
      span: 12,
      component: "input",
      label: "Mobilisation Advance Given 5%",
      name: "mobilization_advance_5",
      placeholder: "Mobilisation Advance Given 5%",
      disabled: true,
    },

    {
      span: 12,
      component: "input",
      label: "Start from 15% of bill value",
      name: "bill_value_15",
      placeholder: "Start from 15% of bill value",
      disabled: true,
    },

    {
      span: 12,
      component: "input",
      label: "Upto 70% of Work Done",
      name: "work_done_70",
      placeholder: "Upto 70% of Work Done",
      disabled: true,
    },

    {
      span: 12,
      component: "input",
      label: "Mobilisation Advance to be recovered within",
      name: "mobilization_advance_recovered",
      placeholder: "Mobilisation Advance to be recovered within",
      disabled: true,
    },


    {
      span: 12,
      component: "input",
      label: "Cummulative Bill Amount  (Excl Taxes)",
      name: "cummulative_amount",
      placeholder: "Cummulative Bill Amount  (Excl Taxes)",
      disabled: true,
    },


    {
      span: 12,
      component: "input",
      label: "Cumilitive Bill Amount",
      name: "cummulative_bill_15",
      placeholder: "Cumilitive Bill Amount",
      disabled: true,
    },

    {
      span: 12,
      component: "input",
      label: "Recovery Amount",
      name: "recovery_amount",
      placeholder: "Recovery Amount",
      disabled: true,
    },

  ],
  actions: {
    onBack: () => navigate(`/budget-tracker/${config?.slugs?.singular}`),
    onFinish: async (values, data) => {

      console.log('valuessss', values, data);
      try {
        const response = await axios.post(`/${config?.slugs?.plural}`, {
          data: {
            ...values,
          },
        });

        if (response?.data) {
          navigate(`/budget-tracker/${config?.slugs?.singular}`)

          return {
            success: true,
            message: `Add new ${config?.entityNames?.singular?.toLowerCase()} successfully!`,
          };
        }

        throw new Error();
      } catch (error) {
        return {
          success: false,
          message: `Add new ${config?.entityNames?.singular?.toLowerCase()} failed!`,
        };
      }
    },
  },
});
