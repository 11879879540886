import { MdArrowBack, MdPeopleAlt } from "react-icons/md";
import { BiCategoryAlt, BiTask } from "react-icons/bi";
import { TbReportAnalytics, TbReport } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { RiPieChart2Fill } from "react-icons/ri";
import { BsQuestionOctagon, BsChatDots } from "react-icons/bs";
import { FaLayerGroup, FaTools } from "react-icons/fa";
import { AiOutlineProject } from "react-icons/ai";
import { IoDocuments } from "react-icons/io5";

const BASE_SLUG = "/budget-tracker";

export const makeNavbar = ({ navigate, user }) => {
  const makeItem = ({ icon, id, label, scopes }) => {
    return {
      id,
      key: `${BASE_SLUG}/${id}`,
      label,
      icon,
      onClick: () => navigate(`${BASE_SLUG}/${id}`),
      scopes,
    };
  };

  let roles = [];

  user?.app_roles?.forEach((appRole) => {
    appRole?.permissions?.forEach((appPermission) => {
      if (!roles?.includes(appPermission)) {
        roles?.push(appPermission);
      }
    });
  });

  roles = roles?.join(",");

  const menuItems = [
    makeItem({
      id: "",
      label: "Budget Tracker",
      icon: <TbReportAnalytics style={styles.icon} />,
    }),
    makeItem({
      id: "mobilization-advance",
      label: "Mobilization Advance",
      icon: <TbReportAnalytics style={styles.icon} />,
    }),
    makeItem({
      id: "report",
      label: "Reports",
      icon: <TbReportAnalytics style={styles.icon} />,
    }),
    {
      key: "back-to-home",
      label: "Return Home",
      icon: <MdArrowBack style={styles.icon} />,
      onClick: () => navigate("/"),
    },
  ];

  let filteredItems = [];

  if (user) {
    menuItems?.forEach((item) => {
      const uid = item?.children ? item?.key : item?.id;


      // if (item?.key === "back-to-home") {
      //   filteredItems?.push(item);
      // }

      if (item?.children) {
        const newChildren = item?.children?.filter((item) => {
          const subItemUid = item?.id;

          return roles?.includes(`QUICK-PROJECTS.${subItemUid?.toUpperCase()}`);
        });

        if (newChildren?.length > 0) {
          item["children"] = newChildren;
          filteredItems?.push(item);
        }
      } else {
        // if (roles?.includes(`QUICK-PROJECTS.${uid?.toUpperCase()}`)) {
        // }
        filteredItems?.push(item);
      }
    });
  } else {
    menuItems?.forEach((item) => {
      if (item?.children) {
        item?.children?.forEach((child) => {
          filteredItems?.push(child);
        });
      } else {
        filteredItems?.push(item);
      }
    });
  }

  return filteredItems;
};

const styles = {
  icon: {
    fontSize: 16,
    color: "#CF6C57",
  },
};
