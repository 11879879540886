import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components";
import { useNavigate } from "react-router-dom";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Select } from "antd";
import qs from "qs";
import config from "./config";
import { axios } from "../../../../../App";
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { ReportDataTable } from "../../../../stocks/components";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const VendorLedgerReport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('')

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`projects`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Projects",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setProjects(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchVendor = async () => {
    try {
      const response = await axios.get(`vendors`);

      if (response?.data) {
        let options = [
          {
            value: "",
            label: "All Vendors",
          },
        ];

        response?.data?.data?.forEach((record) => {
          options?.push({
            value: record?.id,
            label: record?.attributes?.name,
          });
        });

        setVendors(options);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["estimated_amount:desc"],
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `vendor-quotations?${qs.stringify(queryObject)}`
      );

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const downloadReport = () => {
    try {
      const data = {
        code: config?.code,
        params: {
          start_date: searchValues?.start_date
            ? searchValues?.start_date?.format("YYYY-MM-DD")
            : moment().subtract(1000, "years").format("YYYY-MM-DD"),
          end_date: searchValues?.end_date
            ? searchValues?.end_date?.format("YYYY-MM-DD")
            : moment().add(1000, "years").format("YYYY-MM-DD"),
        },
      };

      const link = document.createElement("a");
      link.href = `${process.env.REACT_APP_BASE_API_URL
        }/reporter?${qs.stringify(data)}`;

      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    let filters = [
      {
        $or: [
          {
            heading: {
              $containsi: searchValues?.keyword?.toLowerCase(),
            },
          },
          {
            remarks: {
              $containsi: searchValues?.keyword?.toLowerCase(),
            },
          },
          // {
          //   "vendor.data.attributes.name": {
          //     $containsi: searchValues?.keyword?.toLowerCase(),
          //   },
          // },
        ],
      }
    ];

    if (searchValues?.start_date) {
      filters[0].$or.push({
        createdAt: {
          $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
          $lte: searchValues?.end_date
            ? moment(searchValues?.end_date).format(DATE_TIME_FORMAT)
            : undefined, // If end_date exists, format it, otherwise it's ignored
        },
      });
    }


    if (selectedProject !== "") {
      filters.push({
        project: selectedProject,
      });
    }

    if (selectedVendor !== "") {
      filters.push({
        vendor: selectedVendor,
      });
    }

    fetchData({
      $and: filters,
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
  };

  const exportToExcel = async (data) => {
    // Create a new workbook and a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Budget');

    // Define headers
    worksheet.columns = [
      { header: 'Sr No.', key: 'sr', width: 10 },
      { header: 'Vendor Name', key: 'vendor_name', width: 25 },
      { header: 'Heading', key: 'heading', width: 25 },
      { header: 'Project Name', key: 'project_name', width: 20 },
      { header: 'Urgency Level', key: 'urgency_level', width: 15 },
      { header: 'Taxable Amount', key: 'taxable_amount', width: 20 },
      { header: 'Tax Amount', key: 'tax_amount', width: 20 },
      { header: 'Grand Total Amount', key: 'grand_total', width: 20 },
      { header: 'Material Items', key: 'material_items_string', width: 30 },
      { header: 'Material Groups', key: 'material_groups_string', width: 30 },
      { header: 'Quantities', key: 'quantities_string', width: 20 },
      { header: 'Payment Schedule Dates', key: 'payment_dates_string', width: 30 },
      { header: 'Payment Amounts', key: 'payment_amounts_string', width: 25 },
      { header: 'Delivery Address', key: 'delivery_address', width: 30 },
      { header: 'Approved By', key: 'approved_by', width: 20 },
    ];

    /******************************************************************************
     * Style the header row (row 1) with a different background and font color.
     * Row 1 is automatically populated with column headers from the "columns" definition above.
     ******************************************************************************/
    const heading_row = worksheet.getRow(1);
    heading_row.eachCell((cell) => {
      // Bold white text on a dark blue background
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF1F4E78' }, // A dark blue color
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Insert a blank row to create "spacing" beneath the heading
    worksheet.addRow([]);

    // Opinionated log to check raw data
    console.log('--------------------------------', data);

    /******************************************************************************
     * We will keep a running total of the grand_total_amount so that we can
     * insert a "Grand Total" row at the bottom (optional).
     ******************************************************************************/
    let grand_sum = 0;

    // Map data into rows
    data?.forEach((item, i) => {
      // Gather "normal" data
      const project = item?.project?.data?.attributes || {};
      const vendor = item?.vendor?.data?.attributes || {};
      const address = item?.delivery_address?.data?.attributes || {};
      const approved = item?.approved_by?.data?.attributes || {};

      /*****************************************************************************
       * NOTE:
       * If you're computing the same taxes for each of the "material_item_notes",
       * you might want to confirm you are using the correct index for those references.
       * In the snippet below, we do: item.material_item_notes[i] (which might
       * be the i-th index in the entire data array, not the i-th index of material_item_notes).
       * This can be logical if there's only one note per item or you're absolutely sure
       * the indexes match. Otherwise, you might need a different approach to compute tax details.
       ****************************************************************************/
      const taxable_amount =
        item?.material_item_notes?.[i]?.quantity *
        item?.material_item_notes?.[i]?.amount || 0;
      const cqst = (item?.material_item_notes?.[i]?.cqst || 0) / 100;
      const sqst = (item?.material_item_notes?.[i]?.sqst || 0) / 100;
      const igst = (item?.material_item_notes?.[i]?.igst || 0) / 100;

      // Calculate the total with taxes
      const total_with_tax =
        taxable_amount +
        taxable_amount * cqst +
        taxable_amount * sqst +
        taxable_amount * igst;

      // Flatten material items
      const material_items_string = (item?.material_items || [])
        .map((mi) => mi.material_item || 'null')
        .join(', ');
      const material_groups_string = (item?.material_items || [])
        .map((mi) => mi.material_group || 'null')
        .join(', ');
      const quantities_string = (item?.material_items || [])
        .map((mi) => mi.quantity || 'null')
        .join(', ');

      // Flatten payment schedules
      const payment_dates_string = (item?.payment_schedules || [])
        .map((ps) => ps.scheduled_date || 'null')
        .join(', ');
      const payment_amounts_string = (item?.payment_schedules || [])
        .map((ps) => ps.amount || 'null')
        .join(', ');

      // Convert grand total to number in case it's a string, or default to 0
      const item_grand_total =
        parseFloat(item?.grand_total_amount) || 0;
      grand_sum += item_grand_total; // We'll use this later for the "Grand Total" row

      // Now add a single row for this item
      worksheet.addRow({
        sr: i + 1,
        vendor_name: vendor.name || '',
        heading: item?.heading || '',
        project_name: project.name || '',
        urgency_level: item?.urgency_level || '',
        taxable_amount,
        tax_amount: total_with_tax - taxable_amount,
        grand_total: item?.grand_total_amount || '',
        material_items_string,      // Flattened list of all material items
        material_groups_string,     // Flattened list of all material groups
        quantities_string,          // Flattened list of quantities
        payment_dates_string,       // Flattened list of all payment schedule dates
        payment_amounts_string,     // Flattened list of all payment amounts
        delivery_address: `${address.address_line_1 || ''}, ${address.address_line_2 || ''}`.trim(),
        approved_by: `${approved.first_name || ''} ${approved.last_name || ''}`.trim(),
      });
    });

    /******************************************************************************
     * Optionally, add a "Grand Total" row at the bottom, merging cells or coloring
     * to highlight the total. We also add a blank row afterwards for spacing.
     * "Grand Total" row below can be styled or merged as desired.
     ******************************************************************************/
    if (data?.length) {
      // Blank row before the total (to visually separate the list from total)
      worksheet.addRow([]);

      const total_label = 'GRAND TOTAL:';
      const total_row = worksheet.addRow({
        sr: '', // these empty fields keep the other cells blank
        vendor_name: '',
        heading: '',
        project_name: '',
        urgency_level: '',
        taxable_amount: '',
        tax_amount: '',
        grand_total: grand_sum.toFixed(2), // If we want two decimals
        material_items_string: '',
        material_groups_string: '',
        quantities_string: '',
        payment_dates_string: '',
        payment_amounts_string: '',
        delivery_address: '',
        approved_by: '',
      });

      // We can style the last row: for example, bold text or highlight it
      total_row.getCell('grand_total').font = { bold: true };
      total_row.getCell('grand_total').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE2EFDA' }, // A light greenish color
      };

      // If we want the label "GRAND TOTAL:" displayed in the column just before the grand_total
      // (which is column 'tax_amount' or 'grand_total'?), we can set the text manually:
      total_row.getCell('tax_amount').value = total_label;
      total_row.getCell('tax_amount').font = { bold: true };

      // Insert a blank row beneath the "Grand Total"
      worksheet.addRow([]);
    }

    // Save Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'vendor-ledger.xlsx');
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
    fetchProjects();
    fetchVendor();
  }, []);

  console.log('Vendors Ledgers', data)

  return (
    <ReportDataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns?.map((column) => ({
          ...column,
          ...getColumnProps(column),
        })),
      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
        onRefresh: refreshData,
      }}
      custom_excel_report={() => exportToExcel(data)}

      Toolbar={
        <div className="toolbar">
          <Row gutter={16}>
            <Col span={10}>
              <Input
                value={searchValues?.keyword}
                placeholder="Search for keyword..."
                onChange={(e) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }))
                }
              />
            </Col>

            <Col span={7}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    start_date: value,
                  }))
                }
                value={searchValues?.start_date}
                placeholder="Select start date"
                style={styles.datePicker}
              />
            </Col>

            <Col span={7}>
              <DatePicker
                onChange={(value) =>
                  setSearchValues((prev) => ({
                    ...prev,
                    end_date: value,
                  }))
                }
                value={searchValues?.endDate}
                placeholder="Select end date"
                style={styles.datePicker}
              />
            </Col>

            <Col span={7}>
              <Select
                value={selectedProject}
                onChange={setSelectedProject}
                options={projects}
                style={{ width: "100%", marginTop: '20px' }}
                placeholder="Select Project"
              />
            </Col>
            <Col span={7}>
              <Select
                value={selectedVendor}
                onChange={setSelectedVendor}
                options={vendors}
                style={{ width: "100%", marginTop: '20px' }}
                placeholder="Select Project"
              />
            </Col>
          </Row>

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }
      buttons={[
        <Tooltip placement="top" title="Refresh">
          <Button
            icon={<ReloadOutlined />}
            onClick={refreshData}
            loading={loading}
          />
        </Tooltip>,

      ]}
      onBack={() => navigate(`/quick-stocks/reports`)}
    />
  );
};

const styles = {
  datePicker: {
    width: "100%",
  },
};

export default VendorLedgerReport;
