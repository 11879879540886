import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";
import _ from "lodash";
import { Alert, Breadcrumb, Button, Col, Empty, Form, Input, PageHeader, Row, Space } from "antd";
import { Helmet } from "react-helmet";
import FormElement from "../../../components/FormElement";
import * as XLSX from 'xlsx';


const AddMobilization = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const [form] = Form.useForm();
  const config = makeConfig({ navigate, auth });
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({
    procurement: [
      {
        value: 'agency',
        label: 'Agency',
      },
      {
        value: 'sub_contractor',
        label: 'Sub Contractor',
      },
      {
        value: 'vendor',
        label: 'Vendor',
      },
    ]
  });
  const [selectedPo, setSelectedPo] = useState(null)
  const [allValues, setAllValues] = useState({})

  const [company, setcompany] = useState(null)
  const [selected_invoice, setSelected_invoice] = useState(null)
  const [paidInvoices, setPaidInvoices] = useState([])
  const table_ref = useRef(null);

  const contentTypes = [
    {
      slug: "vendor-quotations",
      query: {
        populate: '*',
        filters: {
          status: 'Approved'
        },
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: `${item?.heading} - (${item?.id})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          vendor_quotation: data,
        })),
    },

    {
      slug: "sub-contractor-quotations",
      query: {
        populate: '*',
        filters: {
          status: 'Approved'
        },
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: `${item?.heading} - (${item?.id})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          sub_contractor_wo: data,
        })),
    },

    {
      slug: "agency-quotations",
      query: {
        populate: '*',
        filters: {
          status: 'Approved'
        },
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: `${item?.heading} - (${item?.id})`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          agency_wo: data,
        })),
    },
  ];


  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);


  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));

    if (inputValues?.vendor_quotation) {
      const po = values?.vendor_quotation?.find(v => v.value === inputValues?.vendor_quotation)
      setSelectedPo(po)
    }

    if (inputValues?.sub_contractor_wo) {
      const po = values?.sub_contractor_wo?.find(v => v.value === inputValues?.sub_contractor_wo)
      setSelectedPo(po)
    }

    if (inputValues?.agency_wo) {
      const po = values?.agency_wo?.find(v => v.value === inputValues?.agency_wo)
      setSelectedPo(po)
    }


    console.log('fedfdsafdsfv', inputValues, values)
    if (inputValues?.received_invoice) {
      const invoice = values?.received_invoice?.find(c => c.value === inputValues?.received_invoice)
      setSelected_invoice(invoice)
    }
  };


  const handle_download_excel = () => {
    // Access the DOM node of our table
    const table_element = table_ref.current;

    // Convert that table into a "book" (spreadsheet workbook)
    const workbook = XLSX.utils.table_to_book(table_element, {
      sheet: 'Sheet1', // The name for the sheet inside the workbook
    });

    // Finally prompt a file download of "report.xlsx"
    XLSX.writeFile(workbook, 'report.xlsx');
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/projects/${selectedPo?.project?.data?.id}?populate=*`);

        if (response?.data) {
          setcompany(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedPo?.project?.data?.id) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPo?.project?.data?.id]);


  useEffect(() => {
    const fetchData = async () => {
      const queryObject = {
        populate: "*",
        filters: {
          project: selectedPo?.project?.data?.id,
          vendor: selectedPo?.vendor?.data?.id,
          sub_contractor: selectedPo?.sub_contractor?.data?.id,
          agency: selectedPo?.agency?.data?.id,
        }
      };

      try {
        const response = await axios.get(`/received-invoices?${qs.stringify(queryObject)}`);

        if (response?.data) {
          const paid_invoices = response?.data?.data?.filter((item) => item?.attributes?.status == 'Released')
          const unpaid_invoices = response?.data?.data?.filter((item) => item?.attributes?.status !== 'Released')

          const invoices = unpaid_invoices?.map((item) => ({
            ...item?.attributes,
            value: item?.id,
            label: item?.attributes?.no
          }))

          setValues(prev => ({
            ...prev,
            received_invoice: invoices,
          }))

          setPaidInvoices(paid_invoices)
        }

      } catch (error) {
        console.log(error);
      }
    };

    if (selectedPo) {
      fetchData();
    }

  }, [selectedPo])

  useEffect(() => {
    const order_value =
      (selectedPo?.material_item_notes ?? selectedPo?.work_item_notes)
        ?.reduce((sum, item) => sum + (item.amount * Number(item.quantity)), 0);

    const recovery_factor = Number(order_value * 0.05 / (order_value * 0.7 - order_value * 0.15)).toFixed(3);

    const cummulative_amount =
      selected_invoice?.reconciliation
        ?.reduce((sum, item) => sum + ((item.amount * Number(item.received_quantity)) * item?.amount_percentage / 100), 0);

    const recovery_amount = cummulative_amount - (cummulative_amount * 0.15)

    const prev_mob_amount = paidInvoices?.reduce((sum, item) => sum + (item?.attributes?.amount_payable), 0);

    const prev_mobilization_recovered = paidInvoices?.length > 0 ? prev_mob_amount : 0

    const current_bill = recovery_amount * recovery_factor - prev_mobilization_recovered

    console.log('Recovery factor',);

    setAllValues((prev) => ({
      ...prev,
      order_value: Number(order_value).toFixed(2),
      mobilization_advance_5: Number(order_value * 0.05).toFixed(2),
      bill_value_15: Number(order_value * 0.15).toFixed(2),
      work_done_70: Number(order_value * 0.7).toFixed(2),
      mobilization_advance_recovered: Number(order_value * 0.7 - order_value * 0.15).toFixed(2),
      recovery_factor: recovery_factor,
      cummulative_amount: Number(cummulative_amount).toFixed(2),
      cummulative_bill_15: Number(cummulative_amount * 0.15).toFixed(2),
      recovery_amount: Number(recovery_amount).toFixed(2),
      mobilization_advance_recovery: Number(recovery_amount * recovery_factor).toFixed(2),
      prev_mobilization_recovered: prev_mobilization_recovered,
      current_bill_recovery: Number(current_bill).toFixed(2),
    }));

  }, [selectedPo, selected_invoice])

  useEffect(() => {
    form.setFieldsValue(allValues);
  }, [allValues])


  console.log('Mobilization Advances', allValues, paidInvoices)

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={() => config?.actions?.onFinish(allValues)}
        style={styles.form}
        scrollToFirstError
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormElement
              values={values}
              {...config?.components?.find((item) => item?.name === "procurement")}
            />
          </Col>

          {allValues?.procurement == "vendor" && (
            <Col span={12}>
              <FormElement
                values={values}
                {...config?.components?.find((item) => item?.name === "vendor_quotation")}
              />
            </Col>
          )}

          {allValues?.procurement == "agency" && (
            <Col span={12}>
              <FormElement
                values={values}
                {...config?.components?.find((item) => item?.name === "agency_wo")}
              />
            </Col>
          )}


          {allValues?.procurement == "sub_contractor" && (
            <Col span={12}>
              <FormElement
                values={values}
                {...config?.components?.find((item) => item?.name === "sub_contractor_wo")}
              />
            </Col>
          )}

          <Col span={12}>
            <FormElement
              values={values}
              {...config?.components?.find((item) => item?.name === "received_invoice")}
            />
          </Col>

          <div className="public-form-body" style={{ width: '80%' }}>
            <div className="public-form-section">

              <table ref={table_ref} className="public-form-general-info">
                <tr>
                  <td>Name of Company </td>
                  <td>{company?.data?.attributes?.company?.data?.attributes?.name}</td>
                </tr>

                <tr>
                  <td>Company Address</td>
                  <td>{company?.data?.attributes?.company?.data?.attributes?.address_line_1}</td>
                </tr>

                <tr>
                  <td>Project Name </td>
                  <td>{selectedPo?.project?.data?.attributes?.name}</td>
                </tr>

                <tr>
                  <td>Name of Contractor </td>
                  <td>
                    {selectedPo?.sub_contractor?.data?.attributes?.name ?? selectedPo?.agency?.data?.attributes?.name ?? selectedPo?.vendor?.data?.attributes?.name}
                  </td>
                </tr>

                <tr>
                  <td>Work Order Value (Excl Taxes) </td>
                  <td>
                    {allValues?.order_value}
                  </td>
                </tr>

                <tr>
                  <td>Mobilisation Advance Given 5% </td>
                  <td>
                    {allValues?.mobilization_advance_5}
                  </td>
                </tr>

                <tr>
                  <td>Start from 15% of bill value
                  </td>
                  <td>
                    {allValues?.bill_value_15}
                  </td>
                </tr>

                <tr>
                  <td>Upto 70% of Work Done
                  </td>
                  <td>
                    {allValues?.work_done_70}
                  </td>
                </tr>

                <tr>
                  <td>Mobilisation Advance to be recovered
                  </td>
                  <td>
                    {allValues?.mobilization_advance_recovered}
                  </td>
                </tr>

                <tr>
                  <td>Cummulative Bill Amount (Excl Taxes)
                  </td>
                  <td>
                    {allValues?.cummulative_amount}
                  </td>
                </tr>

                <tr>
                  <td>Start from 15% of bill value
                  </td>
                  <td>
                    {allValues?.cummulative_bill_15}
                  </td>
                </tr>

                <tr>
                  <td>Recovery Amount
                  </td>
                  <td>
                    {allValues?.recovery_amount}
                  </td>
                </tr>

                <tr>
                  <td>Mobilization Advance Recovery
                  </td>
                  <td>
                    {allValues?.mobilization_advance_recovery}
                  </td>
                </tr>

                <tr>
                  <td>Previous Mobilization Recovered
                  </td>
                  <td>
                    {allValues?.prev_mobilization_recovered}
                  </td>
                </tr>

                <tr>
                  <td>Current Bill Recovery
                  </td>
                  <td>
                    {allValues?.current_bill_recovery}
                  </td>
                </tr>

              </table>
            </div>
          </div>


        </Row>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={config?.actions?.onBack}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>

            <Button type="primary" onClick={handle_download_excel}>Download</Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const Errors = ({ errors }) => {
  if (errors?.length > 0) {
    return (
      <Col span={24}>
        <div style={styles.errors}>
          <h4 style={styles.errorsTitle}>Errors ({errors?.length})</h4>

          {errors?.map((message, index) => (
            <Alert
              key={index}
              message={message}
              type="error"
              style={styles.alert}
              showIcon
            />
          ))}
        </div>
      </Col>
    );
  }
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
  pageTitle: {
    whiteSpace: "normal", // Allow text to wrap to the next line
    overflow: "hidden",
  },
};

export default AddMobilization;
