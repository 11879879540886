import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Button, Popconfirm, Tooltip, notification, Input } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import qs from "qs";
import config from "./config";
import _ from "lodash";
import { MAX_PAGINATION_LIMIT } from "../../../../../../constants";
import { axios } from "../../../../../../App";
import moment from "moment";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ReportDataTable } from "../../../../../stocks/components";


const MobilizationAdvanceReport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [searched, setSearched] = useState(false);

  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        pagination: {
          limit: MAX_PAGINATION_LIMIT,
        },
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `${config?.slugs?.plural}?${qs.stringify(queryObject)}`
      );

      console.log('budgets', response?.data?.data)

      if (response?.data) {
        const mappedData = await Promise.all(
          response?.data?.data?.map(async (item) => {
            const po =
              item?.attributes?.agency_work_order?.data ??
              item?.attributes?.sub_contractor_work_order?.data ??
              item?.attributes?.vendor_quotation?.data;

            let res = null;

            if (item?.attributes?.vendor_quotation?.data) {
              const queryObject = {
                populate: "*",
                filters: {
                  id: item?.attributes?.vendor_quotation?.data?.id,
                },
              };

              try {
                res = await axios.get(`vendor-quotations?${qs.stringify(queryObject)}`);
                console.log("Vendor Quotation Response:", res);
              } catch (error) {
                console.error("Error fetching vendor quotation:", error);
              }
            }

            return {
              ...item?.attributes,
              id: item?.id,
              vendor_quotation: res?.data?.data?.[0] || null,
              po: po,
            };
          })
        );

        setData(mappedData);


        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 10,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const exportToExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Annexure');


    worksheet.columns = [
      { header: 'Sr No.', key: 'sr_no', width: 10 },
      { header: 'Description of Work', key: 'description_of_work', width: 25 },
      { header: 'Work Order Quantity', key: 'work_order_quantity', width: 20 },
      { header: 'BOQ Previous', key: 'boq_previous', width: 15 },
      { header: 'BOQ Current', key: 'boq_current', width: 15 },
      { header: 'BOQ Cumulative', key: 'boq_cumulative', width: 15 },
      { header: 'as per WO Terms RATE', key: 'as_per_wo_terms_rate', width: 20 },
      { header: 'STAGE', key: 'stage', width: 15 },
      { header: 'Rate/Unit', key: 'rate_per_unit', width: 15 },
      { header: 'Amt (Rs) Previous', key: 'amount_previous', width: 15 },
      { header: 'Amt (Rs) Current', key: 'amount_current', width: 15 },
      { header: 'Amt (Rs) Cumulative', key: 'amount_cumulative', width: 15 },
      { header: 'Balance WO Quantity', key: 'balance_work_order_qty', width: 20 },
      { header: 'CURRENT ERP QTY', key: 'current_erp_qty', width: 15 },
      {
        header: 'Current Qty on Measurement\nSheet Page No.',
        key: 'current_qty_measurement_page',
        width: 25
      }
    ];


    data.forEach((item, index) => {

      const sr_no_value = index + 1;

      const row_data = {
        sr_no: sr_no_value,
        description_of_work: item?.received_invoice?.reconciliation?.[0]?.task?.data?.attributes?.name || '',
        work_order_quantity: item.order_value || '',
        boq_previous: item.prev_mobilization_recovered || 0,
        boq_current: item.bill_value_15 || 0,
        boq_cumulative: item.cummulative_bill_15 || 0,
        as_per_wo_terms_rate: item.recovery_factor || 0,
        stage: item.work_done_70 || 0,
        rate_per_unit: item.mobilization_advance_5 || 0,
        amount_previous: item.cummulative_amount || 0,
        amount_current: item.current_bill_recovery || 0,
        amount_cumulative: item.recovery_amount || 0,
        balance_work_order_qty: 0,  // Placeholder unless you have a formula
        current_erp_qty: 0,  // No direct field found, you can put something else
        current_qty_measurement_page: 'page no'  // You can fill an actual page number here
      };

      worksheet.addRow(row_data);
    });

    // 4. Write the workbook to a buffer, then trigger a file save
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'mobilization-advance.xlsx');
  }



  const deleteItem = async (id) => {
    try {
      const response = await axios.delete(`${config?.slugs?.plural}/${id}`);

      if (response?.data) {
        notification["success"]({
          message: `Remove ${config?.entityNames?.singular}`,
          description: `Remove ${config?.entityNames?.singular} successfully!`,
        });

        refreshData();
      } else {
        throw new Error();
      }
    } catch (error) {
      notification["error"]({
        message: `Remove ${config?.entityNames?.singular}`,
        description: `Remove ${config?.entityNames?.singular} failed!`,
      });
    }
  };

  const getColumnFilterProps = ({ dataIndex, references = {} }) => {
    if (typeof references?.fieldName === "function") {
      const transformer = references?.fieldName;

      return {
        render: (data) => transformer(data?.data?.attributes) || "N/A",
      };
    } else {
      return {
        render: (data) =>
          data?.data?.attributes?.[references?.fieldName] || "N/A",
      };
    }
  };

  const getColumnProps = (column) => {
    if (column?.references) {
      return getColumnFilterProps(column);
    }

    return column;
  };

  const onSearch = () => {
    setSearched(true);

    fetchData({
      name: {
        $containsi: searchValues?.keyword?.toLowerCase(),
      },
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData(null);
    setSearched(false);
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues) && !loading && searched) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };

  useEffect(() => {
    fetchData(filters);
  }, []);

  console.log('Tracker', data)

  return (
    <ReportDataTable
      data={data}
      config={{
        ...config,
        columns: config?.columns
          ?.map((column) => ({
            ...column,
            ...getColumnProps(column),
          }))

      }}
      loading={loading}
      pagination={pagination}
      actions={{
        onRefresh: refreshData,
      }}

      custom_excel_report={() => exportToExcel(data)}

      Toolbar={
        <div className="toolbar">
          <Input
            value={searchValues?.keyword}
            placeholder="Search for keyword..."
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                keyword: e.target.value,
              }))
            }
          />

          <div className="toolbar-buttons">
            <p className="toolbar-results">{getResultsMessage()}</p>

            <Space>
              <Button onClick={onReset}>Reset</Button>
              <Button type="primary" onClick={onSearch}>
                Search
              </Button>
            </Space>
          </div>
        </div>
      }

    />
  );
};

export default MobilizationAdvanceReport;
