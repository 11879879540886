import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { makeConfig } from "./config";
import { useSelector } from "react-redux";
import { fetchData } from "../../../../../utils/helpers/data.helper";
import { axios } from "../../../../../App";
import qs from "qs";
import _ from "lodash";
import { Alert, Breadcrumb, Button, Col, Empty, Form, Input, PageHeader, Row, Space } from "antd";
import { Helmet } from "react-helmet";
import FormElement from "../../../components/FormElement";
import moment from "moment";


const Page = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const auth = useSelector((state) => state?.auth);
  const [form] = Form.useForm();
  const config = makeConfig({ navigate, auth });
  const [submitting, setSubmitting] = useState(false);
  const [values, setValues] = useState({
    invoice_type: [
      {
        value: "Proforma Invoice",
        label: "Proforma Invoice",
      },
      {
        value: "Tax Invoice",
        label: "Tax Invoice"
      }
    ],

    status: [
      {
        value: "Released",
        label: "Released",
      },
      {
        value: "Pending",
        label: "Pending",
      }
    ]
  });

  const [selectorValues, setSelectorValues] = useState({});
  const [allValues, setAllValues] = useState({})
  const [selected_invoice, setSelected_invoice] = useState(null)


  const contentTypes = [
    {
      slug: "employees",
      transformer: (item) => ({
        value: item?.id,
        label: `${item?.first_name} ${item?.last_name}`,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          employee: data,
          approved_by: data,
          approved_by_accounts: data,
        })),
    },

    {
      slug: "received-invoices",
      query: {
        populate: '*',
      },
      transformer: (item) => ({
        ...item,
        value: item?.id,
        label: item?.no,
      }),
      onSuccess: (data) =>
        setValues((prev) => ({
          ...prev,
          invoice_numbers: data,
        })),
    },
  ];


  useEffect(() => {
    contentTypes?.forEach((contentType) => {
      fetchData(contentType);
    });
  }, []);


  const handleValuesChange = (_, inputValues) => {
    setAllValues((prev) => ({
      ...prev,
      ...inputValues,
    }));
  };



  const calculateMaterialAmount = (paymentSchedule, materialItemNotes, includeTaxes = true) => {

    // Find the corresponding materialItemNote based on the material_group
    const materialItemNote = materialItemNotes?.find(
      (item) => item?.material_group === paymentSchedule?.material_group?.data?.id
    );

    // If no corresponding materialItemNote is found, return 0
    if (!materialItemNote) {
      return 0;
    }

    // Calculate the base amount based on percentage
    const baseAmount =
      (Number(paymentSchedule.amount_percentage) / 100) *
      (materialItemNote.amount * (materialItemNote?.received_quantity ?? paymentSchedule?.received_quantity));

    if (!includeTaxes) {
      // If taxes are not included, return the base amount
      return baseAmount;
    }

    // Calculate tax amounts (assuming cqst and sqst are percentages)
    const cgstTax = isNaN(materialItemNote.cqst) ? 0 : (materialItemNote.cqst / 100) * baseAmount;
    const sgstTax = isNaN(materialItemNote.sqst) ? 0 : (materialItemNote.sqst / 100) * baseAmount;
    const igstTax = isNaN(materialItemNote.igst) ? 0 : (materialItemNote.igst / 100) * baseAmount;


    // Calculate the total amount, including taxes
    const totalAmount = baseAmount + cgstTax + sgstTax + igstTax;

    return totalAmount;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const queryObject = {
          populate: "*",
        };

        const response = await axios.get(
          `/received-invoices/${id}?${qs.stringify(queryObject)}`
        );

        if (response?.data) {
          setAllValues({
            invoice_numbers: response?.data?.data?.id,
            invoice_type: response?.data?.data?.attributes?.invoice_type,
            amount_payable: response?.data?.data?.attributes?.amount_payable,
            invoice_date: moment(response?.data?.data?.attributes?.invoice_date),
            status: response?.data?.data?.attributes?.status,
            approved_by_accounts: response?.data?.data?.attributes?.approved_by_accounts?.data?.[0]?.id,
          });

        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  useEffect(() => {
    if (allValues?.invoice_numbers) {
      const invoice = values?.invoice_numbers?.find(invoice => invoice.id == allValues?.invoice_numbers)

      setSelected_invoice(invoice)
    }
    form.setFieldsValue(allValues);

  }, [allValues])



  console.log('Payment Tracker', selected_invoice)

  return (
    <>
      <Helmet>
        <title>{config?.pageTitle}</title>
      </Helmet>

      <Breadcrumb style={styles.breadcrumbs}>
        {config?.breadcrumbs?.map((breadcrumb, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={breadcrumb?.path}>{breadcrumb?.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>

      <PageHeader
        title={config?.title}
        style={styles.pageHeader}
        onBack={config?.actions?.onBack}
      ></PageHeader>

      <Form
        form={form}
        name={config?.id}
        layout="vertical"
        onFinish={config?.actions?.onFinish}
        style={styles.form}
        values={allValues}
        scrollToFirstError
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "invoice_numbers")}
            />
          </Col>

          <div className="public-form-body" style={{ padding: '0 14px' }}>
            {selected_invoice && (
              <div className="public-form-section">
                {/* <h2 className="public-form-section-title">Material Items</h2> */}

                <table className="public-form-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      {/* <th>Material Group</th>
                          <th>Material Item</th> */}
                      {/* <th>Material Unit</th> */}
                      <th style={{ width: "20%" }}>Item Description</th>
                      <th>Quantity</th>
                      <th style={{ width: "10%" }}>Labour Cost(₹)</th>
                      <th style={{ width: "10%" }}>Material Cost(₹)</th>
                      <th style={{ width: "10%" }}>Rate(₹)</th>
                      <th style={{ width: "10%" }}>CGST (%)</th>
                      <th style={{ width: "10%" }}>SGST (%)</th>
                      <th style={{ width: "10%" }}>IGST (%)</th>
                      <th style={{ width: "10%" }}>Amount (%)</th>
                      <th style={{ width: "10%" }}>Taxable Amount(₹)</th>
                      <th style={{ width: "10%" }}>Total with Tax(₹)</th>
                    </tr>
                  </thead>


                  <tbody>
                    {selected_invoice?.payment_schedule?.material_items?.length > 0 &&
                      selected_invoice?.payment_schedule?.material_items?.map((materialItem, index) => {

                        return (
                          <tr key={materialItem?.id}>
                            <td className="text-center text-bold">{index + 1}</td>
                            {/* <td>{materialItem?.material_group || "N/A"}</td>
                                <td>{materialItem?.material_item || "N/A"}</td> */}
                            <td dangerouslySetInnerHTML={{ __html: selected_invoice?.payment_schedule?.description }}></td>
                            <td>{selected_invoice?.reconciliation?.[index]?.quantity || 0}</td>
                            <td style={{ fontWeight: "bold" }}>
                              {selected_invoice?.reconciliation?.[index]?.estimated_labour_cost || 0}
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {selected_invoice?.reconciliation?.[index]?.estimated_material_cost || 0}
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {selected_invoice?.reconciliation?.[index]?.amount || 0}
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {selected_invoice?.reconciliation?.[index]?.cqst || 0}
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {selected_invoice?.reconciliation?.[index]?.sqst || 0}
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {selected_invoice?.reconciliation?.[index]?.igst || 0}
                            </td>
                            <td>{selected_invoice?.payment_schedule?.amount_percentage}</td>
                            <td>{calculateMaterialAmount(selected_invoice?.payment_schedule, selected_invoice?.reconciliation, false)}</td>
                            <td>{calculateMaterialAmount(selected_invoice?.payment_schedule, selected_invoice?.reconciliation, true)}</td>
                          </tr>
                        );
                      })}


                    {(!selected_invoice?.payment_schedule?.material_items ||
                      selected_invoice?.payment_schedule?.material_items?.length === 0) && (
                        <tr>
                          <td colSpan={8} style={{ padding: 24 }}>
                            <Empty />
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "invoice_type")}
            />
          </Col>

          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "cheque_no")}
            />
          </Col>

          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "invoice_date")}
            />
          </Col>

          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "amount_payable")}
            />
          </Col>

          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "status")}
            />
          </Col>

          <Col span={12}>
            <FormElement
              values={values}
              form={form}
              {...config?.components?.find((item) => item?.name === "approved_by_accounts")}
            />
          </Col>

        </Row>

        <Space style={styles.buttons}>
          <Space>
            <Button onClick={config?.actions?.onBack}>Cancel</Button>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Space>
        </Space>
      </Form>
    </>
  );
};

const Errors = ({ errors }) => {
  if (errors?.length > 0) {
    return (
      <Col span={24}>
        <div style={styles.errors}>
          <h4 style={styles.errorsTitle}>Errors ({errors?.length})</h4>

          {errors?.map((message, index) => (
            <Alert
              key={index}
              message={message}
              type="error"
              style={styles.alert}
              showIcon
            />
          ))}
        </div>
      </Col>
    );
  }
};

const styles = {
  pageHeader: {
    padding: 0,
    paddingBottom: 24,
  },
  form: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
  },
  loaderContainer: {
    textAlign: "center",
    padding: 12,
  },
  header: {
    marginBottom: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errors: {
    marginBottom: 24,
  },
  errorsTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 6,
  },
  alert: {
    marginBottom: 6,
  },
  pageTitle: {
    whiteSpace: "normal", // Allow text to wrap to the next line
    overflow: "hidden",
  },
};

export default Page;
